<template>
  <div>
    <router-view></router-view>
    <app-loader></app-loader>
  </div>
</template>

<script>
export default {
  created() {
    this.$http.get('/params').then((response) => {
      if (response.status === 200) {
        this.$store.commit('setAppConfig', response.data);
      }
    });
  },
};
</script>
