<template>
  <ValidationProvider
    :rules="config.rules"
    :ref="field"
    :name="inputPlaceholder"
    :vid="field"
    v-slot="{ invalid }"
    slim
  >
    <input
      class="form-control"
      type="file"
      :id="fieldId"
      :name="field"
      @change="onFileChange"
      :class="{ 'is-invalid': invalid }"
    />
  </ValidationProvider>
</template>

<script>
import fieldMixin from './fieldMixin';

export default {
  mixins: [fieldMixin],
  methods: {
    onFileChange(event) {
      if (event.target.files && event.target.files[0]) {
        this.$refs[this.field].validate(event);
        this.$emit('input', this.field, event.target.files[0]);
      }
    },
  },
};
</script>

<style scoped>
.form-control::-webkit-file-upload-button,
.form-control::file-selector-button {
  display: none;
}
</style>
