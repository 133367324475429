import moment from 'moment';
import ru from 'moment/locale/ru';

moment.updateLocale('ru', ru);

function formatTimeAgo(date) {
  if (!date) {
    return null;
  }
  const now = moment();
  const targetDate = moment(date);

  if (now.isSame(targetDate, 'day')) {
    return `Сегодня в ${targetDate.format('HH:mm:ss')}`;
  }
  if (now.clone().subtract(1, 'days').isSame(targetDate, 'day')) {
    return `Вчера в ${targetDate.format('HH:mm:ss')}`;
  }
  if (now.clone().subtract(2, 'days').isSame(targetDate, 'day')) {
    return `Позавчера в ${targetDate.format('HH:mm:ss')}`;
  }
  if (now.year() !== targetDate.year()) {
    return targetDate.format('DD.MM.YYYY в HH:mm:ss');
  }
  return targetDate.format('D MMMM в HH:mm:ss');
}

export default {
  bind(el, binding) {
    el.innerHTML = formatTimeAgo(binding.value);
  },
  update(el, binding) {
    if (binding.value !== binding.oldValue) {
      el.innerHTML = formatTimeAgo(binding.value);
    }
  },
};
