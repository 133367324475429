<template>
  <section class="orders-main">
    <div class="orders-main__kanban">
      <h2 class="title title_fz24">Мои заказы</h2>
      <div class="d-flex">
        <div class="btn-group">
          <button
            type="button"
            v-for="(name, id) in countries"
            :class="countryBtnClass(id)"
            :key="`country_${id}`"
            @click.prevent="setCurrentCountry(id)"
          >{{name}}</button>
        </div>
        <button
          type="button"
          slot="header"
          class="column__btn ms-auto"
          @click.prevent="openModal"
        >
          Добавить новый заказ
        </button>
      </div>
      <div class="orders-main__wrapper">
        <OrdersColumn
          class="swiper-slide"
          v-for="status in visibleStatuses"
          :key="`category_${status.id}`"
          :title="status.name"
          :category="status"
          :orders="statusOrders(status)"
          @change-status="onChangeStatus"
          @openModal="openModal"
        />
      </div>
    </div>
    <form-modal
      title="Добавление заказа"
      :fields="modalFields"
      :is-opened="formModalOpened"
      ref="formModal"
      @submit="onFormSave"
      @close="closeModal"
      @updateFormField="setValue"
    />
  </section>
</template>

<script>
import moment from 'moment';
import FormModal from '@/components/modals/form/FormModal.vue';
import formModalMixin from '@/components/modals/form/formModalMixin';
import WsMixin from '@/mixins/WsMixin';
import OrdersColumn from './components/OrdersColumn.vue';

export default {
  mixins: [formModalMixin, WsMixin],
  components: {
    OrdersColumn,
    FormModal,
  },
  data() {
    return {
      activeIndex: 0,
      formModalOpened: false,
      orders: [],
      statuses: {},
      countries: {},
      isLoading: false,
      currentCountry: 1,
    };
  },
  computed: {
    visibleStatuses() {
      const visibleStatuses = {};
      Object.keys(this.statuses).forEach((key) => {
        if (parseInt(this.statuses[key].country.id, 10) === this.currentCountry) {
          visibleStatuses[key] = this.statuses[key];
        }
      });
      return visibleStatuses;
    },
    modalFields() {
      return {
        description: {
          labelText: 'Описание',
          rules: 'required',
          value: this.getValue('description'),
        },
        // country: {
        //   labelText: 'Страна:',
        //   component: 'select-field',
        //   options: this.countries,
        //   rules: 'required',
        //   value: this.getValue('country'),
        // },
      };
    },
  },
  methods: {
    countryBtnClass(id) {
      const classes = ['btn'];
      if (this.currentCountry === parseInt(id, 10)) {
        classes.push('btn-primary');
      } else {
        classes.push('btn-secondary');
      }
      return classes;
    },
    setCurrentCountry(id) {
      this.$set(this, 'currentCountry', parseInt(id, 10));
    },
    statusOrders(status) {
      return this.orders
        .filter((order) => order.status.id === status.id)
        .sort((a, b) => new Date(b.status_updated) - new Date(a.status_updated));
    },
    onChangeStatus(event) {
      const newStatusId = event.new.toString();
      const oldStatusId = event.old.toString();
      if (newStatusId === oldStatusId) {
        return;
      }
      const orderId = parseInt(event.order, 10);
      if (typeof this.statuses[newStatusId] === 'undefined') {
        throw new Error('Status not found');
      }
      this.$http.put(`/orders/${orderId}/status`, { status: newStatusId }).then((response) => {
        if (response.status === 200 && response.data && !response.data.errors) {
          this.shangeStatusOnWs(orderId, newStatusId, response.data.name);
        }
      }).catch(() => {});
    },
    shangeStatusOnWs(orderId, newStatusId, newStatusName = null) {
      const orderIndex = this.orders.findIndex((order) => parseInt(order.id, 10) === orderId);
      if (orderIndex !== -1) {
        this.updateOrderStatus({
          order: this.orders[orderIndex].id,
          newStatus: newStatusId,
        });
        this.sendToWs('orders/status', {
          orderId: this.orders[orderIndex].id,
          newStatusId,
        });
        if (newStatusName) {
          this.sendToWs('orders/message', {
            order: this.orders[orderIndex],
            message: {
              message: `Заказ переведен в статус "${newStatusName}"`,
              username: this.$auth.user().fio,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              data: {
                type: 'status',
              },
            },
          });
        }
      }
    },
    onWsMessage(message) {
      if (message.action === 'addOrder') {
        this.addOrder(message.data);
      }
      if (message.action === 'orderStatus') {
        this.updateOrderStatus(message.data);
      }
    },
    updateOrderStatus({ order, newStatus }) {
      // Order: ID заявки
      // newStatus - ID нового статуса
      if (typeof this.statuses[parseInt(newStatus, 10)] === 'undefined') {
        return;
      }
      const status = this.statuses[parseInt(newStatus, 10)];
      const orderIndex = this.orders.findIndex((o) => parseInt(o.id, 10) === parseInt(order, 10));
      if (orderIndex !== -1) {
        this.$set(this.orders[orderIndex], 'status', status);
        this.$set(this.orders[orderIndex], 'status_id', status.id);
        this.$set(this.orders[orderIndex], 'status_updated', moment().format('YYYY-MM-DD HH:mm:ss'));
      }
    },
    onFormSave() {
      this.$store.commit('upRequests');
      const formData = {
        description: this.formFields.description,
        country: this.currentCountry,
      };
      this.$http.post('/orders', formData).then((response) => {
        this.$store.commit('downRequests');
        if (response.data.errors) {
          this.$refs.formModal.addErrors(response.data.errors);
          return;
        }
        this.sendToWs('orders/addOrder', {
          order: response.data,
        });
        this.closeModal();
        this.addOrder(response.data);
      });
    },
    closeModal() {
      this.formModalOpened = false;
      this.clearFields();
    },
    openModal() {
      this.formModalOpened = true;
    },
    getData() {
      this.$store.commit('upRequests');
      this.$http.get('/orders').then((response) => {
        this.$store.commit('downRequests');
        if (response.status === 200) {
          this.orders = response.data.orders;
          this.statuses = response.data.statuses;
        }
      });
    },
    addOrder(newOrder) {
      const updatedOrders = [newOrder, ...this.orders];
      this.$set(this, 'orders', updatedOrders);
    },
  },
  created() {
    if (!this.$store.getters.appConfig('orders_enabled')) {
      this.$router.push('/');
    }
  },
  mounted() {
    this.getData();
    this.$http.get('/orders/available-countries').then((response) => {
      if (response.status === 200) {
        this.$set(this, 'countries', response.data);
      }
    });
  },
};
</script>
