<template>
  <div class="product-page" v-if="product !== null">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h2 class="title title_fz24">
        {{ product.brand }} <span>{{ product.name }}</span>
      </h2>
      <div class="filter-container ms-auto">
        <div class="d-flex align-items-center">
          <span class="me-2">Выбран период:</span>
          <div class="filter-calendar">
            <date-range-picker-button v-model="dates"></date-range-picker-button>
          </div>
        </div>
      </div>
    </div>
    <top-stats
      ref="topStats"
      :product-id="product.sku"
      :dates="dates"
      @loaded="topStats = $event"
    ></top-stats>
    <product-info :product="product"></product-info>
    <sales-main
      ref="salesStats"
      :product="product"
      :dates="dates"
      :top-stats="topStats"
    ></sales-main>
    <stocks-remains ref="stocksRemains" :product="product"></stocks-remains>
    <stocks-sales
      ref="stocksSales"
      :product="product"
      section-class="mt-4"
      style="display: none"
    ></stocks-sales>
    <similar-products ref="similarProducts" :product="product"></similar-products>
    <categories :dates="dates" :product="product" style="display: none"></categories>
    <queries :product="product" :dates="dates" style="display: none"></queries>
  </div>
</template>

<script>
import DateRangePickerButton from '@/components/form/DateRangePickerButton.vue';
import fetchCategory from '@/mixins/fetchCategory';

import TopStats from './product/TopStats.vue';
import ProductInfo from './product/ProductInfo.vue';
import SalesMain from './product/sales/SalesMain.vue';
// import Remains from './product/Remains.vue';
import StocksSales from './product/StocksSales.vue';
import SimilarProducts from './product/SimilarProducts.vue';
import StocksRemains from './product/StocksRemains.vue';
import Categories from './product/Categories.vue';
import Queries from './product/Queries.vue';

export default {
  mixins: [fetchCategory],
  components: {
    StocksRemains,
    DateRangePickerButton,
    TopStats,
    ProductInfo,
    SalesMain,
    // Remains,
    Categories,
    Queries,
    StocksSales,
    SimilarProducts,
  },
  data() {
    return {
      topStats: null,
      product: null,
      dates: {
        startDate: this.$moment().subtract(7, 'days').toDate(),
        endDate: this.$moment().subtract(1, 'days').toDate(),
      },
      refsForDateUpdate: ['salesStats', 'similarProducts', 'topStats'],
    };
  },
  computed: {
    productId() {
      return this.$route.params.sku;
    },
  },
  methods: {
    updateData() {
      this.$refs.salesStats.update();
      this.$refs.similarProducts.update();
      this.$refs.topStats.update();
      this.$refs.stocksRemains.getData();
      this.$refs.stocksSales.getData();
    },
    updateWhenDateChanged() {
      this.refsForDateUpdate.forEach((ref) => {
        this.$refs[ref].update();
      });
    },
    fetchProduct(doneFn = null) {
      this.$http
        .get('/analytics/goods/view', {
          params: { id: this.productId },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'product', response.data);
            if (typeof doneFn === 'function') {
              doneFn(response.data);
            }
          }
        });
    },
  },
  watch: {
    dates(v, ov) {
      if (v && (!ov || ov !== v)) {
        this.$nextTick(() => {
          this.updateWhenDateChanged();
        });
      }
    },
    $route() {
      this.fetchProduct(() => {
        this.$nextTick(() => {
          this.updateData();
        });
      });
    },
  },
  mounted() {
    this.fetchProduct();
  },
};
</script>
