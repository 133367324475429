<template>
  <div class="column">
    <div class="column__title">{{ title }}</div>
    <div class="column__elements">
      <draggable
        class="column__items"
        :data-category="category.id"
        :list="orders"
        group="my-group"
        @end="onDraggableEnd"
      >
        <OrdersCard v-for="order in orders" :key="order.id" :order="order" />
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import OrdersCard from './OrdersCard.vue';

export default {
  components: {
    OrdersCard,
    draggable,
  },
  props: {
    category: Object,
    orders: Array,
    title: {
      type: String,
      require: true,
    },
  },
  methods: {
    openAddModal() {
      this.$emit('openModal');
    },
    onDraggableEnd(event) {
      this.$emit('change-status', {
        order: event.item.dataset.order,
        old: event.from.dataset.category,
        new: event.to.dataset.category,
      });
    },
  },
};
</script>
