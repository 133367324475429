<template>
  <div class="actions__message-container">
    <div class="actions__message" v-if="!isStatusMessage">
      <div class="actions__message-time">
        <template v-if="hasUsername">{{this.message.username}} в </template>
        {{messageTime}}
      </div>
      <div class="actions__message-text">
        {{message.message}}
      </div>
    </div>
    <div class="actions__status-change" v-else>
      <div class="actions__status-change-icon">
        <img src="../../../../assets/img/orders-actions.svg" alt="" />
      </div>
      <div class="actions__status-change-time">{{messageTime}}</div>
      <div class="actions__status-change-text">{{message.message}}</div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['message'],
  computed: {
    messageTime() {
      return this.message.date.split(' ')[1];
    },
    isStatusMessage() {
      return this.message.data && this.message.data.type && this.message.data.type === 'status';
    },
    hasUsername() {
      return !!this.message.username.length;
    },
  },
};
</script>
