<template>
  <ul class="sidebar__nav-sublinks">
    <li>
      <span class="sidebar__nav-sublink">{{ category.vname }}</span>
    </li>
    <li v-for="(link, index) in links" :key="`category-${index}-link`">
      <router-link
        :to="link.url"
        class="sidebar__nav-sublink"
        active-class="sidebar__nav-sublink_active"
        >{{ link.label }}</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    category() {
      return this.$store.getters.currentCategory;
    },
    links() {
      if (!this.category) {
        return [];
      }
      return [
        {
          label: 'Товары',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'goods',
            },
          },
        },
        {
          label: 'Категории',
          url: {
            name: 'analytics:category-categories',
            params: {
              categoryId: this.category.vsourceid,
            },
          },
        },
        {
          label: 'Бренды',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'brands',
            },
          },
        },
        {
          label: 'Анализ ниш',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'category-analysis',
            },
          },
        },
        {
          label: 'Ценовой сегмент',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'price-segment',
            },
          },
        },
      ];
    },
  },
};
</script>
