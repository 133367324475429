<template>
  <div id="carousel" class="carousel slide carousel-fade d-none d-lg-block" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div
        class="carousel-item product-info__large-img"
        :class="{ active: isFirstIndex(index) }"
        v-for="(image, index) in images"
        :key="`product_image_${index}`"
      >
        <img :src="image.big" class="d-block w-100" alt="" />
      </div>
    </div>
    <div class="carousel-indicators" v-if="needViewThumbs">
      <button
        v-for="(image, index) in images"
        :key="`product_image_${index}_thumb`"
        type="button"
        data-bs-target="#carousel"
        :data-bs-slide-to="index"
        class="product-info__small-img"
        :class="{ active: isFirstIndex(index) }"
        :aria-label="`Slide ${index + 1}`"
      >
        <img class="d-block w-100" :src="image.thumb" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import 'bootstrap/js/src/carousel';

export default {
  props: ['product'],
  computed: {
    hasImages() {
      return this.product.images !== null && !!this.product.images.length;
    },
    needViewThumbs() {
      return this.hasImages && this.product.images.length > 1;
    },
    images() {
      if (!this.hasImages) {
        return [
          {
            big: this.product.firstImage,
          },
        ];
      }
      return this.product.images;
    },
  },
  methods: {
    isFirstIndex(index) {
      return parseInt(index, 10) === 0;
    },
  },
};
</script>
