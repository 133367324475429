export default {
  namespaced: true,
  state() {
    return {
    };
  },
  actions: {
  },
  mutations: {
  },
  getters: {
  },
};
