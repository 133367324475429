export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fieldId() {
      return this.config.id || this.config.name || null;
    },
    componentName() {
      return this.config.component || 'text-field';
    },
    inputPlaceholder() {
      return this.config.inputPlaceholder ? this.config.inputPlaceholder : this.config.labelText;
    },
  },
  methods: {
    onInput($event) {
      this.$refs[this.field].validate($event);
      this.$emit('input', this.field, $event.target.value);
    },
  },
};
