<template>
  <div>
    <h2 class="title title_fz24" style="display: none">Рабочий стол продавца</h2>
    <top-stats style="display: none"></top-stats>
    <top-products></top-products>
    <sales-dynamics style="display: none"></sales-dynamics>
    <last-orders style="display: none"></last-orders>
  </div>
</template>

<script>
import TopStats from './dashboard/TopStats.vue';
import TopProducts from './dashboard/TopProducts.vue';
import SalesDynamics from './dashboard/SalesDynamics.vue';
import LastOrders from './dashboard/LastOrders.vue';

export default {
  components: {
    TopStats,
    TopProducts,
    SalesDynamics,
    LastOrders,
  },
};
</script>
