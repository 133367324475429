<template>
  <div class="data-table-actions">
    <div
      class="data-table-actions__action d-flex justify-content-evenly"
      :key="`datatable-actions_${index}_${actionIndex}`"
      v-for="(action, actionIndex) in actions">
      <router-link :to="action.route" v-if="action.route" v-bind="linkBinds(action)">
        <i :class="action.icon" v-if="action.icon"></i>
      </router-link>
      <a href="#" @click.prevent.stop="action.action(row)" v-if="action.action" v-bind="linkBinds(action)">
        <i :class="action.icon" v-if="action.icon"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: Object,
    actions: Array,
    index: Number,
  },
  methods: {
    linkBinds(action) {
      return action.binds || [];
    },
  },
};
</script>
