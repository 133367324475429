<template>
  <div class="app-loader-container" v-if="show">
    <span class="loader"></span>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      requestsCount: 'requestsCount',
    }),
    show() {
      return this.requestsCount > 0;
    },
  },
};
</script>
