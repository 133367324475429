<template>
  <div class="product-categories-positions">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div>
        <h2 class="title title_fz24">Запросы и позиции по ним</h2>
        <p class="mb-0">
          По которым представлен товар в поиске и занимаемая им позиция (первые 200 с сортировкой по
          позиции последней даты, частотности)
        </p>
      </div>
      <div class="align-self-start">
        <button class="btn btn-primary btn-lg">История позиций</button>
      </div>
    </div>
    <div class="mt-2">
      <div class="table__wrapper table__wrapper_mt20 table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="table__dark" scope="col">Ключевое слово</th>
              <th class="table__dark" scope="col">Частота WB</th>
              <th class="table__dark" scope="col">Уник. запросы OZ</th>
              <th class="table__dark" scope="col">Результаты</th>
              <th class="table__dark" scope="col">Позиция</th>
              <th scope="col">1.02</th>
              <th scope="col">2.02</th>
              <th scope="col">3.02</th>
              <th scope="col">4.02</th>
              <th scope="col">5.02</th>
              <th scope="col">6.02</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table__dark"><span>Видимость</span></td>
              <td class="table__dark" colspan="4"><span>Запросы</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark" colspan="5"><span>Ср. позиция</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">Железная указка</td>
              <td class="table__dark"><span>124</span></td>
              <td class="table__dark"><span>124</span></td>
              <td class="table__dark"><span>124</span></td>
              <td class="table__dark"><span>124</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">фонарик для кота</td>
              <td class="table__dark"><span>1241</span></td>
              <td class="table__dark"><span>1241</span></td>
              <td class="table__dark"><span>1241</span></td>
              <td class="table__dark"><span>1241</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">импринт</td>
              <td class="table__dark"><span>34523</span></td>
              <td class="table__dark"><span>34523</span></td>
              <td class="table__dark"><span>34523</span></td>
              <td class="table__dark"><span>34523</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">лазерная указка usb зарядка</td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">лазер для кошек usb</td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td class="table__dark"><span>234</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
            <tr>
              <td class="table__dark">лазер для кошек на зарядке</td>
              <td class="table__dark"><span>123</span></td>
              <td class="table__dark"><span>123</span></td>
              <td class="table__dark"><span>123</span></td>
              <td class="table__dark"><span>123</span></td>
              <td>142</td>
              <td>123</td>
              <td>23</td>
              <td>42</td>
              <td>524</td>
              <td>53</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import datesDiffMixin from '@/mixins/datesDiffMixin';
// import DataTable from '@/components/dataTable/DataTable.vue';

export default {
  props: ['product', 'dates'],
  mixins: [datesDiffMixin],
  // components: { DataTable },
  // data() {
  //   return {
  //     tableRows: [],
  //   };
  // },
  // computed: {
  //   rowsUrl() {
  //     return '/analytics/goods/queries-positions';
  //   },
  //   baseQueryParams() {
  //     return {
  //       id: this.product.sku,
  //       startDate: this.dates.startDate ? this.$moment(this.dates.startDate).format('DD.MM.YYYY') : null,
  //       endDate: this.dates.endDate ? this.$moment(this.dates.endDate).format('DD.MM.YYYY') : null,
  //     };
  //   },
  //   tableColumns() {
  //     const columns = [
  //       {
  //         field: 'keyword',
  //         label: 'Ключевое слово',
  //         fixed: true,
  //       },
  //       {
  //         field: 'wb_rate',
  //         label: 'Частота WB',
  //         fixed: true,
  //       },
  //       {
  //         field: 'oz_unique_requests',
  //         label: 'Уник. запросы OZ',
  //         fixed: true,
  //       },
  //       {
  //         field: 'results',
  //         label: 'Результаты',
  //         fixed: true,
  //       },
  //       {
  //         field: 'position',
  //         label: 'Позиция',
  //         fixed: true,
  //       },
  //     ];
  //     const dates = this.datesList();
  //     for (let i = 0, iMax = dates.length; i < iMax; i++) {
  //       columns.push({
  //         field: dates[i].replace('.', '_'),
  //         label: dates[i],
  //         fixed: false,
  //       });
  //     }
  //     return columns;
  //   },
  // },
  // methods: {
  //   update() {
  //     this.$refs.salesTable.loadItems(true);
  //   },
  // },
  // mounted() {
  //   this.update();
  // },
  // watch: {
  //   dates(v, ov) {
  //     if (v && (!ov || ov !== v)) {
  //       this.$nextTick(() => {
  //         this.update();
  //       });
  //     }
  //   },
  // },
};
</script>
