<template>
  <div class="mt-4" id="sales-table">
    <data-table
      ref="salesTable"
      :columns="tableColumns"
      :exportable="false"
      :rows-url="rowsUrl"
      table-class="sales-table"
      :base-query-params="baseQueryParams"
    >
      <template #after-tbody v-if="topStats !== null">
        <tfoot>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Суммарно ({{ days }} {{ dateStr }})</td>
            <td colspan="4">{{ topStats.totalSales }} шт.</td>
            <td colspan="3">{{ topStats.totalSum }}</td>
          </tr>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Среднее (в день)</td>
            <td colspan="4">{{ topStats.averagePerDay }} шт.</td>
            <td colspan="3">{{ topStats.averageSum }}</td>
          </tr>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Дней с наличием</td>
            <td colspan="4">{{ topStats.salesWithRemains }} шт.</td>
            <td colspan="3">{{ topStats.salesWithRemainsSum }}</td>
          </tr>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Среднее при наличии</td>
            <td colspan="4">{{ topStats.averagePerDayWithRemains }} шт.</td>
            <td colspan="3">{{ topStats.averageSumWithRemains }}</td>
          </tr>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Потенциал</td>
            <td colspan="4"></td>
            <td colspan="3">{{ topStats.potential }}</td>
          </tr>
          <tr class="sales-results">
            <td colspan="2" class="fw-bold">Упущенная выручка</td>
            <td colspan="4"></td>
            <td colspan="3">{{ topStats.lostProfit }}</td>
          </tr>
        </tfoot>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/dataTable/DataTable.vue';
import datesDiffMixin from '@/mixins/datesDiffMixin';

export default {
  mixins: [datesDiffMixin],
  components: { DataTable },
  props: {
    topStats: Object,
    product: Object,
    dates: {
      type: Object,
      default() {
        return {
          startDate: this.$moment().startOf('day').toDate(),
          endDate: this.$moment().endOf('day').toDate(),
        };
      },
    },
  },
  data() {
    return {
      tableColumns: [
        {
          field: 'date',
          label: 'Дата',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy',
        },
        {
          field: 'quantity',
          label: 'Остаток',
          type: 'piece',
        },
        {
          field: 'orders',
          label: 'Продажи',
          type: 'piece',
        },
        {
          field: 'priceFull',
          label: 'Цена',
          type: 'currency',
        },
        {
          field: 'price',
          label: 'Со скидкой',
          type: 'currency',
        },
        {
          field: 'discount',
          label: 'СПП',
          type: 'percentage',
        },
        {
          field: 'revenue',
          label: 'Выручка',
          type: 'currency',
        },
        {
          field: 'comments',
          label: 'Комментарии',
        },
        {
          field: 'rating',
          label: 'Рейтинг',
        },
      ],
    };
  },
  computed: {
    baseQueryParams() {
      return {
        id: this.product.sku,
        dates: {
          startDate: this.dates.startDate ?
            this.$moment(this.dates.startDate).format('DD.MM.YYYY') :
            null,
          endDate: this.dates.endDate ?
            this.$moment(this.dates.endDate).format('DD.MM.YYYY') :
            null,
        },
      };
    },
    rowsUrl() {
      return '/analytics/goods/product-sales';
    },
  },
  methods: {
    update() {
      this.$refs.salesTable.loadItems(true);
    },
  },
  mounted() {
    this.update();
  },
};
</script>
