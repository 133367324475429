<template>
  <div class="categories-list">
    <h2 class="title title_fz24 mb-3">Категории</h2>
    <div class="row">
      <div class="col-md-4 col-xs-12" v-if="!!column1.length">
        <main-category
          v-for="category in column1"
          :category="category"
          :column="1"
          :key="`category_column1_${category.kid}`"
          @select-category="setCurrentCategory"
        ></main-category>
      </div>
      <div class="col-md-4 col-xs-12" v-if="!!column2.length">
        <main-category
          v-for="category in column2"
          :category="category"
          :column="2"
          :key="`category_column2_${category.kid}`"
          @select-category="setCurrentCategory"
        ></main-category>
      </div>
      <div class="col-md-4 col-xs-12" v-if="!!column3.length">
        <main-category
          v-for="category in column3"
          :category="category"
          :column="3"
          :key="`category_column3_${category.kid}`"
          @select-category="setCurrentCategory"
        ></main-category>
      </div>
    </div>
  </div>
</template>

<script>
import MainCategory from '@/pages/analytics/categories/menu/MainCategory.vue';

export default {
  components: { MainCategory },
  data() {
    return {
      column1: [],
      column2: [],
      column3: [],
      filter: null,
      categories: [],
    };
  },
  computed: {
    storedCategories() {
      return this.$store.getters.categories;
    },
  },
  methods: {
    setCurrentCategory(category) {
      this.$store.commit('setCurrentCategory', category);
    },
    filterCategories(categories, parentVisible) {
      if (this.filter === null || !this.filter.length) {
        return categories;
      }
      return categories
        .map((item) => {
          const children = this.filterCategories(
            item.children || [],
            parentVisible && !!item.vname.toLowerCase().includes(this.filter.toLowerCase()),
          );
          const visible =
            !!item.vname.toLowerCase().includes(this.filter.toLowerCase()) ||
            children.length > 0 ||
            !!parentVisible;

          return { ...item, children, visible };
        })
        .filter((item) => !!item.visible);
    },
    fillColumns(categories) {
      const newCategories = this.filterCategories(JSON.parse(JSON.stringify(categories)));
      const categoriesLength = newCategories.length;
      if (categoriesLength) {
        const itemsInColumn = Math.floor(categoriesLength / 3);
        this.$set(this, 'column1', newCategories.splice(0, itemsInColumn));
        if (newCategories.length) {
          this.$set(this, 'column2', newCategories.splice(0, itemsInColumn));
        }
        if (newCategories.length) {
          this.$set(this, 'column3', newCategories);
        }
      } else {
        this.$set(this, 'column1', []);
        this.$set(this, 'column2', []);
        this.$set(this, 'column3', []);
      }
    },
    get() {
      this.resetCategories();
      this.$store.commit('upRequests');
      this.$http.get('/analytics/category/all').then((response) => {
        this.$store.commit('downRequests');
        this.fillColumns(response.data);
        this.$store.commit('setCategories', response.data);
      });
    },
    resetCategories() {
      this.$set(this, 'column1', []);
      this.$set(this, 'column2', []);
      this.$set(this, 'column3', []);
      this.$store.commit('setCategories', []);
      this.$store.commit('closeAllCategories');
    },
  },
  created() {
    const allOpened = this.$store.getters.allCategoriesOpen;
    if (!this.storedCategories.length || allOpened) {
      this.get();
    } else {
      this.fillColumns(this.storedCategories);
    }
    if (allOpened) {
      this.$store.commit('closeAllCategories');
    }
    this.$store.commit('setCurrentCategory', null);
    const $this = this;
    this.$store.commit('setTopSearchConfig', {
      placeholder: 'Поиск по категориям',
      onSubmitFn(searchString) {
        $this.filter = searchString;
        if ($this.storedCategories.length) {
          $this.fillColumns($this.storedCategories);
        }
        if ($this.filter) {
          $this.$store.commit('openAllCategories');
        } else {
          $this.$store.commit('closeAllCategories');
        }
      },
    });
  },
  destroyed() {
    this.$store.commit('setTopSearchDefault');
  },
};
</script>
