var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.containerClasses},[_c('a',{staticClass:"category-link fw-bold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.childrenOpened = !_vm.childrenOpened}}},[_vm._v(" "+_vm._s(_vm.category.vname)+" "),_c('i',{staticClass:"rigel-icon-sort-down ms-1"})]),(_vm.childrenOpened)?_c('ul',{staticClass:"w-100 child-links-list"},[_c('li',{staticClass:"current-category-link"},[_c('router-link',{staticClass:"category-link",attrs:{"to":{
          name: 'analytics:category',
          params: { categoryId: _vm.category.vsourceid, page: 'goods' },
        },"exact":""}},[_vm._v("Открыть раздел "+_vm._s(_vm.category.vname))])],1),(_vm.hasChildren)?_vm._l((_vm.category.children),function(child){return _c('li',{key:`subcategory-child-${child.vsourceid}`},[(child.children.length)?_c(_vm.$options.name,{tag:"component",attrs:{"container-class":"pt-0","category":child}}):_c('router-link',{staticClass:"category-link",attrs:{"to":{
            name: 'analytics:category',
            params: { categoryId: child.vsourceid, page: 'goods' },
          },"exact":""}},[_vm._v(_vm._s(child.vname))])],1)}):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }