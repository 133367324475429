<template>
  <div class="table-menu__popup-wrap ms-2" v-click-outside="closeColumns">
    <button
      class="btn btn-outline-secondary fw-normal btn-filter-dropdown btn-columns-select"
      @click.prevent="isOpened = !isOpened"
    >
      <i class="fa-solid fa-table d-inline d-sm-none"></i>
      <span class="d-none d-sm-inline">Колонки</span>
    </button>

    <div class="columns-control" v-show="isOpened">
      <div class="columns-control__popup">
        <div class="columns-control__top-actions">
          <a href="#" @click.prevent="isOpened = false">
            <i class="fas fa-arrow-left"></i>
          </a>
          Колонки
          <a href="#" @click.prevent="isOpened = false">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <section class="columns-control__checkboxes">
          <div>
            <div
              class="columns-control-column"
              v-for="column in columns"
              :key="`column_${column.field}`"
            >
              <div class="columns-control-column__toggle">
                <div class="product-form-toggle">
                  <input
                    type="checkbox"
                    name="PolicyNumber"
                    :id="checkboxId(column)"
                    :checked="columnVisible(column)"
                    @change="onCheckboxInput($event, column)"
                    class="product-form-toggle__input"
                  />
                  <label :for="checkboxId(column)" class="product-form-toggle__label">{{
                    column.label
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="table-filters__actions">
          <a href="#" class="ms-auto" @click.prevent="applyColumns">Применить</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['columns', 'id'],
  data() {
    return {
      hiddenColumns: {},
      isOpened: false,
    };
  },
  computed: {
    storageKey() {
      return `${this.id}_hidden_columns`;
    },
    fromStorage() {
      if (localStorage) {
        const fromStorage = localStorage.getItem(this.storageKey);
        if (fromStorage) {
          return JSON.parse(fromStorage);
        }
      }
      return null;
    },
  },
  methods: {
    closeColumns() {
      if (this.isOpened) {
        this.isOpened = false;
      }
    },
    applyColumns() {
      this.isOpened = false;
      this.toStorage();
      this.emitNewColumns();
    },
    emitNewColumns() {
      this.$emit('set-columns', Object.keys(this.hiddenColumns));
    },
    toStorage() {
      if (typeof window.localStorage !== 'undefined') {
        window.localStorage.setItem(this.storageKey, JSON.stringify(this.hiddenColumns));
      }
    },
    onCheckboxInput($event, column) {
      if (!$event.target.checked) {
        this.$set(this.hiddenColumns, column.field, true);
      } else {
        this.$delete(this.hiddenColumns, column.field);
      }
    },
    checkboxId(column) {
      return `table_${this.id}_column_${column.field}`;
    },
    columnVisible(column) {
      return !this.hiddenColumns[column.field];
    },
    hideAll() {
      this.columns.forEach((column) => {
        this.$set(this.hiddenColumns, column.field, true);
      });
    },
    showAll() {
      this.$set(this, 'hiddenColumns', {});
    },
  },
  mounted() {
    const { fromStorage } = this;
    if (fromStorage) {
      this.hiddenColumns = fromStorage;
      this.emitNewColumns();
    }
  },
};
</script>
