<template>
  <div>
    <h2 class="title title_fz24 mb-2" v-if="searchString && resultsCount !== null">
      По запросу: <span>{{ searchString }}</span>
      <template v-if="hasResults">
        {{ resultsCountStrBefore }} {{ resultsCount }} {{ resultsCountStrAfter }}
      </template>
      <template v-else> ничего не найдено </template>
    </h2>
    <data-table
      ref="searchTable"
      selectable
      exportable
      :columns-url="columnsUrl"
      :rows-url="rowsUrl"
      :base-query-params="baseQueryParams"
      @begin-load="onBeginLoad"
      @load="onItemsLoad"
    >
    </data-table>
  </div>
</template>

<script>
import { pluralize } from 'pluralize-ru-ts';

import DataTable from '@/components/dataTable/DataTable.vue';

export default {
  components: { DataTable },
  data() {
    return {
      resultsCount: 0,
    };
  },
  computed: {
    columnsUrl() {
      return '/analytics/goods/columns';
    },
    rowsUrl() {
      return '/analytics/goods/index';
    },
    searchString() {
      return this.$route.query.sku || null;
    },
    baseQueryParams() {
      return { sku: this.searchString };
    },
    hasResults() {
      return !!this.resultsCount;
    },
    resultsCountStrBefore() {
      const pluralizeMe = pluralize('найден', 'найдено', 'найдено');
      return pluralizeMe(this.resultsCount);
    },
    resultsCountStrAfter() {
      const pluralizeMe = pluralize('результат', 'результата', 'результатов');
      return pluralizeMe(this.resultsCount);
    },
  },
  methods: {
    onBeginLoad() {
      this.$set(this, 'resultsCount', null);
    },
    onItemsLoad({ totalRecords }) {
      this.$set(this, 'resultsCount', totalRecords);
    },
    loadData() {
      this.$refs.searchTable.loadColumns();
      this.$refs.searchTable.loadItems();
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.loadData();
      });
    },
  },
  mounted() {
    if (this.searchString) {
      this.loadData();
    }
  },
};
</script>
