<template>
  <div class="category">
    <div class="d-flex mb-2">
      <div class="header-title">
        <slot name="header-title"></slot>
        <slot name="header-subtitle"></slot>
      </div>
      <div class="filter-container ms-auto">
        <div class="d-flex align-items-center">
          <span class="me-2">Выбран период:</span>
          <div class="filter-calendar" style="pointer-events: none">
            <date-range-picker-button v-model="dates"></date-range-picker-button>
          </div>
        </div>
      </div>
    </div>
    <data-table
      ref="itemsTable"
      :id="tableId"
      selectable
      exportable
      :sortable="sortableEnabled"
      :head-filters="hasHeadFilters"
      :filters="filtersConfig"
      :columns-url="columnsUrl"
      :rows-url="rowsUrl"
      :base-query-params="baseQueryParams"
    >
      <template #top-left>
        <template v-if="currentCategory">
          <h2 class="title title_fz24">
            {{ currentCategory.vpath }}
            <span>за {{ days }} {{ dateStr }}</span>
          </h2>
          <a :href="currentCategory.vurl" class="subtitle">{{ currentCategory.vurl }}</a>
        </template>
        <component :is="topLeftComponent" v-if="topLeftComponent"></component>
      </template>
    </data-table>
  </div>
</template>

<script>
import { toPascalCase } from 'js-convert-case';
import DataTable from '@/components/dataTable/DataTable.vue';
import FetchCategory from '@/mixins/fetchCategory';
import datesDiffMixin from '@/mixins/datesDiffMixin';
import DateRangePickerButton from '@/components/form/DateRangePickerButton.vue';
import TopLeftPriceSegment from './categoryComponents/TopLeftPriceSegment.vue';
import TopLeftCategoryAnalysis from './categoryComponents/TopLeftCategoryAnalysis.vue';

const PAGES_WITHOUT_SORT = ['price-segment'];

export default {
  components: {
    DateRangePickerButton,
    DataTable,
    TopLeftPriceSegment,
    TopLeftCategoryAnalysis,
  },
  mixins: [FetchCategory, datesDiffMixin],
  data() {
    return {
      hasHeadFilters: true,
      rows: [],
      columns: [],
      totalRecords: 0,
      tableFilters: {
        enabled: true,
        filters: [],
      },
      dates: {
        startDate: this.$moment().subtract(30, 'days').toDate(),
        endDate: this.$moment().subtract(1, 'days').toDate(),
      },
    };
  },
  computed: {
    sortableEnabled() {
      return !PAGES_WITHOUT_SORT.includes(this.page);
    },
    topLeftComponent() {
      const componentName = `TopLeft${toPascalCase(this.page)}`;
      if (typeof this.$options.components[componentName] !== 'undefined') {
        return componentName;
      }
      return null;
    },
    tableId() {
      return `category_${this.categoryId}_${this.page}`;
    },
    filtersConfig() {
      return {
        enabled: this.tableFilters.enabled,
        filters: this.tableFilters.filters,
      };
    },
    columnsUrl() {
      return `/analytics/${this.page}/columns`;
    },
    rowsUrl() {
      return `/analytics/${this.page}/index`;
    },
    baseQueryParams() {
      const params = {
        category: this.categoryId,
        service: this.currentCategory ? this.currentCategory.vservice : null,
        dates: {},
      };
      if (this.dates) {
        if (this.dates.startDate) {
          params.dates.startDate = this.$moment(this.dates.startDate).format('DD.MM.YYYY 00:00:00');
        }
        if (this.dates.endDate) {
          params.dates.endDate = this.$moment(this.dates.endDate).format('DD.MM.YYYY 23:59:59');
        }
      }
      return params;
    },
  },
  methods: {
    getFilters() {
      this.$http.get(`/analytics/${this.page}/filters`).then((response) => {
        if (response.status === 200) {
          if (response.data !== false) {
            this.$set(this, 'hasHeadFilters', true);
            this.$set(this.tableFilters, 'enabled', true);
            this.$set(this.tableFilters, 'filters', response.data);
          } else {
            this.$set(this, 'hasHeadFilters', false);
            this.$set(this.tableFilters, 'enabled', false);
          }
        }
      });
    },
    afterRoute() {
      this.$refs.itemsTable.loadColumns(true);
      this.$refs.itemsTable.loadItems(true);
      this.getFilters();
    },
    afterFetch() {
      this.$refs.itemsTable.loadColumns();
      this.$refs.itemsTable.loadItems();
      this.getFilters();
    },
  },
  watch: {
    dates(v, ov) {
      if (v && (!ov || ov !== v)) {
        this.$nextTick(() => {
          this.afterRoute();
        });
      }
    },
  },
};
</script>
