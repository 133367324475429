export default {
  methods: {
    onWsMessage() {},
    sendToWs(handler, params) {
      this.$socket.send(JSON.stringify({
        handler,
        params,
      }));
    },
  },
  beforeMount() {
    this.$options.sockets.onmessage = (data) => {
      this.onWsMessage(JSON.parse(data.data));
    };
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  },
};
