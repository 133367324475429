<template>
  <div class="page-card sales-dynamics mt-4">
    <title-with-month-select v-model="date" @change="getData">
      <h2 class="title title_fz24 mb-2">Динамика общих продаж</h2>
      <div class="page-subtitle mb-2">в рублях, магазин <span>"Название"</span></div>
    </title-with-month-select>
    <div>
      <canvas id="salesChart"></canvas>
    </div>
  </div>
</template>

<script>
import TitleWithMonthSelect from '@/components/TitleWithMonthSelect.vue';
import SalesChart from '@/pages/analytics/product/sales/SalesChart.vue';

export default {
  mixins: [SalesChart],
  components: { TitleWithMonthSelect },
  data() {
    return {
      date: this.$moment().format('MM.YYYY'),
    };
  },
  computed: {
    chartConfig() {
      return {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: '',
              pointBackgroundColor: '#00892F',
              pointBorderColor: '#F0F0F0',
              pointBorderWidth: 1,
              pointRadius: 5,
              fill: {
                target: 'start',
                above: this.gradient,
              },
              showLine: false,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.orders,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: this.externalTooltipHandler,
            },
          },
          layout: {
            padding: 0,
          },
        },
      };
    },
  },
  methods: {
    getData() {
      this.$store.commit('upRequests');
      this.$http
        .get('/dashboard/sales-dynamics', {
          params: { date: this.date },
        })
        .then((response) => {
          this.$store.commit('downRequests');
          if (response.status === 200) {
            this.$set(this, 'labels', response.data.dates);
            this.$set(this, 'orders', response.data.orders);
            this.runChart();
          }
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
