<template>
  <div class="card cursor-pointer" :data-order="order.id" @click.prevent="openOrder">
    <div class="card__body">
      <div class="card__header">
        <p class="card__order-note m-0">Заказ №</p>
        <p class="card__order-num m-0">{{ order.id }}</p>
      </div>
      <ul class="card__chars">
        <li class="card__char">
          <p class="card__char-title m-0">Сумма:</p>
          <p class="card__char-value m-0">{{ sum }} руб.</p>
        </li>
        <li class="card__char">
          <p class="card__char-title m-0">Страна:</p>
          <p class="card__char-value m-0">{{ order.country.name }}</p>
        </li>
      </ul>
    </div>
    <div class="card__amount">
      <p class="card__amount-text">Товаров:</p>
      <div class="card__amount-num">{{ order.goodsCount }}</div>
    </div>
    <div class="card__comment">
      <p class="card__comment-title m-0">Комментарий:</p>
      <p class="card__comment-text m-0">{{ order.description }}</p>
    </div>
    <p class="card__date" v-relative-time="order.status_updated"></p>
  </div>
</template>

<script>
import numberFormat from '@/utils/numberFormat';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sum() {
      return numberFormat(this.order.totalSum);
    },
    viewRouteParams() {
      return {
        name: 'orders:view',
        params: {
          order: this.order.id,
        },
      };
    },
  },
  methods: {
    openOrder() {
      this.$router.push(this.viewRouteParams);
    },
  },
};
</script>
