<template>
  <section class="product-info">
    <h2 class="title title_fz24">Информация о товаре</h2>
    <div class="product-info__wrapper">
      <carousel :product="product"></carousel>
      <div class="product-info__main">
        <div class="product-info__top">
          <div class="title title_fz24">
            Артикул: <span>{{ product.sku }}</span>
          </div>
          <div class="product-info__top-notes d-none d-lg-flex">
            <div class="product-info__top-note" v-if="product.updated">
              Обновлено: <span>{{ product.updated | datetime }}</span>
            </div>
            <div class="product-info__top-note" v-if="product.created">
              Впервые обнаружен: <span>{{ product.created | datetime }}</span>
            </div>
          </div>
        </div>
        <div class="product-info__name">
          Название: <span>{{ product.name }}</span>
        </div>
        <div class="product-info_object">Предмет: {{ product.category.vpath }}</div>
        <div class="product-info__chars">
          <div class="product-info__chars-item">
            Бренд: <span>{{ product.brand }}</span>
          </div>
          <div class="product-info__chars-item">Выкуп: <span>?</span></div>
          <div class="product-info__chars-item">
            Поставщик: <span>{{ product.seller }}</span>
          </div>
          <div class="product-info__chars-item">С учетом возврата: <span>?</span></div>
          <div class="product-info__chars-item">
            Цена до скидки:
            <span>
              {{ product.priceFull
              }}<template v-if="product.discount">, скидка {{ product.discount }}%</template>
            </span>
          </div>
        </div>
        <div class="product-info__blocks">
          <div class="product-info__block">
            <div class="product-info__block-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_404_1952)">
                  <path
                    d="M12 0.75C5.37 0.75 0 5.79 0 12C0 18.21 5.37 23.25 12 23.25C19.035 23.25 13.56 19.005 16.185 16.425C17.325 15.3 18.63 15.12 19.845 15.12C20.4 15.12 20.94 15.165 21.435 15.165C22.92 15.165 24.015 14.82 24.015 12.015C24 5.79 18.63 0.75 12 0.75ZM21.975 13.23C21.9 13.245 21.735 13.26 21.42 13.26C21.21 13.26 20.985 13.26 20.745 13.245C20.46 13.245 20.16 13.23 19.83 13.23C18.495 13.23 16.545 13.425 14.85 15.075C13.095 16.815 13.5 18.975 13.74 20.28C13.785 20.55 13.86 20.94 13.875 21.18C13.635 21.255 13.095 21.375 11.985 21.375C6.405 21.375 1.86 17.175 1.86 12C1.86 6.825 6.42 2.625 12 2.625C17.58 2.625 22.125 6.825 22.125 12C22.125 12.75 22.035 13.11 21.975 13.23Z"
                    fill="#989898"
                  ></path>
                  <path
                    d="M8.84875 14.205C7.30375 14.205 6.05875 15.405 6.05875 16.89C6.05875 18.375 7.31875 19.575 8.84875 19.575C10.3787 19.575 11.6388 18.375 11.6388 16.89C11.6388 15.405 10.3787 14.205 8.84875 14.205ZM8.84875 17.73C8.32375 17.73 7.88875 17.355 7.88875 16.89C7.88875 16.425 8.32375 16.05 8.84875 16.05C9.37375 16.05 9.80875 16.425 9.80875 16.89C9.80875 17.355 9.37375 17.73 8.84875 17.73ZM8.54875 10.845C8.54875 9.36 7.28875 8.16 5.75875 8.16C4.22875 8.16 2.96875 9.36 2.96875 10.845C2.96875 12.33 4.22875 13.53 5.75875 13.53C7.28875 13.53 8.54875 12.33 8.54875 10.845ZM5.75875 11.685C5.23375 11.685 4.79875 11.31 4.79875 10.845C4.79875 10.38 5.23375 10.005 5.75875 10.005C6.28375 10.005 6.71875 10.38 6.71875 10.845C6.71875 11.31 6.28375 11.685 5.75875 11.685ZM11.0537 3.75C9.50875 3.75 8.26375 4.95 8.26375 6.435C8.26375 7.92 9.52375 9.12 11.0537 9.12C12.5837 9.12 13.8437 7.92 13.8437 6.435C13.8437 4.95 12.5837 3.75 11.0537 3.75ZM11.0537 7.275C10.5287 7.275 10.0937 6.9 10.0937 6.435C10.0937 5.97 10.5287 5.595 11.0537 5.595C11.5787 5.595 12.0137 5.97 12.0137 6.435C12.0137 6.9 11.5787 7.275 11.0537 7.275ZM14.7587 9.24C14.7587 10.725 16.0187 11.925 17.5487 11.925C19.0787 11.925 20.3387 10.725 20.3387 9.24C20.3387 7.755 19.0787 6.555 17.5487 6.555C16.0187 6.555 14.7587 7.755 14.7587 9.24ZM18.5087 9.24C18.5087 9.705 18.0737 10.08 17.5487 10.08C17.0237 10.08 16.5887 9.705 16.5887 9.24C16.5887 8.775 17.0237 8.4 17.5487 8.4C18.0737 8.4 18.5087 8.775 18.5087 9.24Z"
                    fill="#989898"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_404_1952">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="product-info__block-text">
              Цвет:<span>{{ product.color }}</span>
            </div>
          </div>
          <div class="product-info__block">
            <div class="product-info__block-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_404_1952)">
                  <path
                    d="M12 0.75C5.37 0.75 0 5.79 0 12C0 18.21 5.37 23.25 12 23.25C19.035 23.25 13.56 19.005 16.185 16.425C17.325 15.3 18.63 15.12 19.845 15.12C20.4 15.12 20.94 15.165 21.435 15.165C22.92 15.165 24.015 14.82 24.015 12.015C24 5.79 18.63 0.75 12 0.75ZM21.975 13.23C21.9 13.245 21.735 13.26 21.42 13.26C21.21 13.26 20.985 13.26 20.745 13.245C20.46 13.245 20.16 13.23 19.83 13.23C18.495 13.23 16.545 13.425 14.85 15.075C13.095 16.815 13.5 18.975 13.74 20.28C13.785 20.55 13.86 20.94 13.875 21.18C13.635 21.255 13.095 21.375 11.985 21.375C6.405 21.375 1.86 17.175 1.86 12C1.86 6.825 6.42 2.625 12 2.625C17.58 2.625 22.125 6.825 22.125 12C22.125 12.75 22.035 13.11 21.975 13.23Z"
                    fill="#989898"
                  ></path>
                  <path
                    d="M8.84875 14.205C7.30375 14.205 6.05875 15.405 6.05875 16.89C6.05875 18.375 7.31875 19.575 8.84875 19.575C10.3787 19.575 11.6388 18.375 11.6388 16.89C11.6388 15.405 10.3787 14.205 8.84875 14.205ZM8.84875 17.73C8.32375 17.73 7.88875 17.355 7.88875 16.89C7.88875 16.425 8.32375 16.05 8.84875 16.05C9.37375 16.05 9.80875 16.425 9.80875 16.89C9.80875 17.355 9.37375 17.73 8.84875 17.73ZM8.54875 10.845C8.54875 9.36 7.28875 8.16 5.75875 8.16C4.22875 8.16 2.96875 9.36 2.96875 10.845C2.96875 12.33 4.22875 13.53 5.75875 13.53C7.28875 13.53 8.54875 12.33 8.54875 10.845ZM5.75875 11.685C5.23375 11.685 4.79875 11.31 4.79875 10.845C4.79875 10.38 5.23375 10.005 5.75875 10.005C6.28375 10.005 6.71875 10.38 6.71875 10.845C6.71875 11.31 6.28375 11.685 5.75875 11.685ZM11.0537 3.75C9.50875 3.75 8.26375 4.95 8.26375 6.435C8.26375 7.92 9.52375 9.12 11.0537 9.12C12.5837 9.12 13.8437 7.92 13.8437 6.435C13.8437 4.95 12.5837 3.75 11.0537 3.75ZM11.0537 7.275C10.5287 7.275 10.0937 6.9 10.0937 6.435C10.0937 5.97 10.5287 5.595 11.0537 5.595C11.5787 5.595 12.0137 5.97 12.0137 6.435C12.0137 6.9 11.5787 7.275 11.0537 7.275ZM14.7587 9.24C14.7587 10.725 16.0187 11.925 17.5487 11.925C19.0787 11.925 20.3387 10.725 20.3387 9.24C20.3387 7.755 19.0787 6.555 17.5487 6.555C16.0187 6.555 14.7587 7.755 14.7587 9.24ZM18.5087 9.24C18.5087 9.705 18.0737 10.08 17.5487 10.08C17.0237 10.08 16.5887 9.705 16.5887 9.24C16.5887 8.775 17.0237 8.4 17.5487 8.4C18.0737 8.4 18.5087 8.775 18.5087 9.24Z"
                    fill="#989898"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_404_1952">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="product-info__block-text">
              Рейтинг:<span>{{ product.rating }}%</span>
            </div>
          </div>
          <div class="product-info__block">
            <div class="product-info__block-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_404_1952)">
                  <path
                    d="M12 0.75C5.37 0.75 0 5.79 0 12C0 18.21 5.37 23.25 12 23.25C19.035 23.25 13.56 19.005 16.185 16.425C17.325 15.3 18.63 15.12 19.845 15.12C20.4 15.12 20.94 15.165 21.435 15.165C22.92 15.165 24.015 14.82 24.015 12.015C24 5.79 18.63 0.75 12 0.75ZM21.975 13.23C21.9 13.245 21.735 13.26 21.42 13.26C21.21 13.26 20.985 13.26 20.745 13.245C20.46 13.245 20.16 13.23 19.83 13.23C18.495 13.23 16.545 13.425 14.85 15.075C13.095 16.815 13.5 18.975 13.74 20.28C13.785 20.55 13.86 20.94 13.875 21.18C13.635 21.255 13.095 21.375 11.985 21.375C6.405 21.375 1.86 17.175 1.86 12C1.86 6.825 6.42 2.625 12 2.625C17.58 2.625 22.125 6.825 22.125 12C22.125 12.75 22.035 13.11 21.975 13.23Z"
                    fill="#989898"
                  ></path>
                  <path
                    d="M8.84875 14.205C7.30375 14.205 6.05875 15.405 6.05875 16.89C6.05875 18.375 7.31875 19.575 8.84875 19.575C10.3787 19.575 11.6388 18.375 11.6388 16.89C11.6388 15.405 10.3787 14.205 8.84875 14.205ZM8.84875 17.73C8.32375 17.73 7.88875 17.355 7.88875 16.89C7.88875 16.425 8.32375 16.05 8.84875 16.05C9.37375 16.05 9.80875 16.425 9.80875 16.89C9.80875 17.355 9.37375 17.73 8.84875 17.73ZM8.54875 10.845C8.54875 9.36 7.28875 8.16 5.75875 8.16C4.22875 8.16 2.96875 9.36 2.96875 10.845C2.96875 12.33 4.22875 13.53 5.75875 13.53C7.28875 13.53 8.54875 12.33 8.54875 10.845ZM5.75875 11.685C5.23375 11.685 4.79875 11.31 4.79875 10.845C4.79875 10.38 5.23375 10.005 5.75875 10.005C6.28375 10.005 6.71875 10.38 6.71875 10.845C6.71875 11.31 6.28375 11.685 5.75875 11.685ZM11.0537 3.75C9.50875 3.75 8.26375 4.95 8.26375 6.435C8.26375 7.92 9.52375 9.12 11.0537 9.12C12.5837 9.12 13.8437 7.92 13.8437 6.435C13.8437 4.95 12.5837 3.75 11.0537 3.75ZM11.0537 7.275C10.5287 7.275 10.0937 6.9 10.0937 6.435C10.0937 5.97 10.5287 5.595 11.0537 5.595C11.5787 5.595 12.0137 5.97 12.0137 6.435C12.0137 6.9 11.5787 7.275 11.0537 7.275ZM14.7587 9.24C14.7587 10.725 16.0187 11.925 17.5487 11.925C19.0787 11.925 20.3387 10.725 20.3387 9.24C20.3387 7.755 19.0787 6.555 17.5487 6.555C16.0187 6.555 14.7587 7.755 14.7587 9.24ZM18.5087 9.24C18.5087 9.705 18.0737 10.08 17.5487 10.08C17.0237 10.08 16.5887 9.705 16.5887 9.24C16.5887 8.775 17.0237 8.4 17.5487 8.4C18.0737 8.4 18.5087 8.775 18.5087 9.24Z"
                    fill="#989898"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_404_1952">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="product-info__block-text">
              Цена:<span>{{ product.price }}</span>
            </div>
          </div>
          <div class="product-info__block">
            <div class="product-info__block-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_404_1952)">
                  <path
                    d="M12 0.75C5.37 0.75 0 5.79 0 12C0 18.21 5.37 23.25 12 23.25C19.035 23.25 13.56 19.005 16.185 16.425C17.325 15.3 18.63 15.12 19.845 15.12C20.4 15.12 20.94 15.165 21.435 15.165C22.92 15.165 24.015 14.82 24.015 12.015C24 5.79 18.63 0.75 12 0.75ZM21.975 13.23C21.9 13.245 21.735 13.26 21.42 13.26C21.21 13.26 20.985 13.26 20.745 13.245C20.46 13.245 20.16 13.23 19.83 13.23C18.495 13.23 16.545 13.425 14.85 15.075C13.095 16.815 13.5 18.975 13.74 20.28C13.785 20.55 13.86 20.94 13.875 21.18C13.635 21.255 13.095 21.375 11.985 21.375C6.405 21.375 1.86 17.175 1.86 12C1.86 6.825 6.42 2.625 12 2.625C17.58 2.625 22.125 6.825 22.125 12C22.125 12.75 22.035 13.11 21.975 13.23Z"
                    fill="#989898"
                  ></path>
                  <path
                    d="M8.84875 14.205C7.30375 14.205 6.05875 15.405 6.05875 16.89C6.05875 18.375 7.31875 19.575 8.84875 19.575C10.3787 19.575 11.6388 18.375 11.6388 16.89C11.6388 15.405 10.3787 14.205 8.84875 14.205ZM8.84875 17.73C8.32375 17.73 7.88875 17.355 7.88875 16.89C7.88875 16.425 8.32375 16.05 8.84875 16.05C9.37375 16.05 9.80875 16.425 9.80875 16.89C9.80875 17.355 9.37375 17.73 8.84875 17.73ZM8.54875 10.845C8.54875 9.36 7.28875 8.16 5.75875 8.16C4.22875 8.16 2.96875 9.36 2.96875 10.845C2.96875 12.33 4.22875 13.53 5.75875 13.53C7.28875 13.53 8.54875 12.33 8.54875 10.845ZM5.75875 11.685C5.23375 11.685 4.79875 11.31 4.79875 10.845C4.79875 10.38 5.23375 10.005 5.75875 10.005C6.28375 10.005 6.71875 10.38 6.71875 10.845C6.71875 11.31 6.28375 11.685 5.75875 11.685ZM11.0537 3.75C9.50875 3.75 8.26375 4.95 8.26375 6.435C8.26375 7.92 9.52375 9.12 11.0537 9.12C12.5837 9.12 13.8437 7.92 13.8437 6.435C13.8437 4.95 12.5837 3.75 11.0537 3.75ZM11.0537 7.275C10.5287 7.275 10.0937 6.9 10.0937 6.435C10.0937 5.97 10.5287 5.595 11.0537 5.595C11.5787 5.595 12.0137 5.97 12.0137 6.435C12.0137 6.9 11.5787 7.275 11.0537 7.275ZM14.7587 9.24C14.7587 10.725 16.0187 11.925 17.5487 11.925C19.0787 11.925 20.3387 10.725 20.3387 9.24C20.3387 7.755 19.0787 6.555 17.5487 6.555C16.0187 6.555 14.7587 7.755 14.7587 9.24ZM18.5087 9.24C18.5087 9.705 18.0737 10.08 17.5487 10.08C17.0237 10.08 16.5887 9.705 16.5887 9.24C16.5887 8.775 17.0237 8.4 17.5487 8.4C18.0737 8.4 18.5087 8.775 18.5087 9.24Z"
                    fill="#989898"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_404_1952">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="product-info__block-text">Комиссия:<span>?% FBO / ?% FBS</span></div>
          </div>
        </div>
      </div>
      <div class="product-info__btn-group">
        <a href="#" class="btn btn-primary" v-scroll-to="'#sales-table'">История изменений</a>
        <a href="#" class="btn btn-primary" v-scroll-to="'#product-similar'">Смотреть похожие</a>
        <a :href="externalLink" class="btn btn-secondary" target="_blank">Отзывы и ответы</a>
      </div>
      <comment :product="product"></comment>
    </div>
  </section>
</template>

<script>
import Comment from './info/Comment.vue';
import Carousel from './info/Carousel.vue';

export default {
  props: ['product'],
  components: { Carousel, Comment },
  computed: {
    category() {
      if (this.$parent.currentCategory) {
        return this.$parent.currentCategory;
      }
      return this.product.category;
    },
    externalLink() {
      return `https://www.wildberries.ru/catalog/${this.product.sku}/detail.aspx#footerTabs`;
    },
  },
};
</script>
