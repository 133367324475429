import Chart from 'chart.js/auto';

export default {
  data() {
    return {
      chart: null,
      chartElementId: null,
    };
  },
  computed: {
    chartCanvas() {},
    chartConfig() {},
  },
  methods: {
    runChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(this.chartCanvas, this.chartConfig);
    },
  },
};
