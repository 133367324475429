<template>
  <div class="product-categories-positions">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div>
        <h2 class="title title_fz24">Категории и позиции в них</h2>
        <p class="mb-0">В которых представлен товар и занимаемая им позиция</p>
      </div>
      <div class="align-self-start">
        <button class="btn btn-primary btn-lg">История позиций</button>
      </div>
    </div>
    <div class="mt-2">
      <data-table
        ref="salesTable"
        :sortable="false"
        :head-filters="false"
        :columns="tableColumns"
        :exportable="false"
        :rows-url="rowsUrl"
        :base-query-params="baseQueryParams"
      ></data-table>
    </div>
  </div>
</template>

<script>
import datesDiffMixin from '@/mixins/datesDiffMixin';
import DataTable from '@/components/dataTable/DataTable.vue';

export default {
  props: ['product', 'dates'],
  mixins: [datesDiffMixin],
  components: { DataTable },
  data() {
    return {
      tableRows: [],
    };
  },
  computed: {
    rowsUrl() {
      return '/analytics/goods/categories-positions';
    },
    baseQueryParams() {
      return {
        id: this.product.sku,
        startDate: this.dates.startDate ?
          this.$moment(this.dates.startDate).format('DD.MM.YYYY') :
          null,
        endDate: this.dates.endDate ? this.$moment(this.dates.endDate).format('DD.MM.YYYY') : null,
      };
    },
    tableColumns() {
      const columns = [
        {
          field: 'category',
          label: 'Категория',
          fixed: true,
        },
      ];
      const dates = this.datesList();
      for (let i = 0, iMax = dates.length; i < iMax; i++) {
        columns.push({
          field: dates[i].replace('.', '_'),
          label: dates[i],
          fixed: false,
        });
      }
      return columns;
    },
  },
  methods: {
    update() {
      this.$refs.salesTable.loadItems(true);
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    dates(v, ov) {
      if (v && (!ov || ov !== v)) {
        this.$nextTick(() => {
          this.update();
        });
      }
    },
  },
};
</script>
