<template>
  <div :class="containerClass">
    <input
      type="text"
      class="form-control"
      :class="inputClass"
      placeholder="Введите текст поиска"
      :value="filter.value"
      @input="onInput"
      v-bind="inputProps"
    />
    <div class="d-flex mt-2">
      <button class="btn btn-primary ms-auto" @click.prevent="onSetValue">Применить</button>
    </div>
  </div>
</template>

<script>
import filter from './filter';

export default {
  mixins: [filter],
};
</script>
