<template>
  <div class="top-product">
    <div class="top-product__name">
      <img :src="getServiceImage" alt="" /><a
        :href="externalLink"
        class="btn btn-secondary"
        target="_blank"
        >{{ product.sku }}</a
      >
      - {{ product.name }}
    </div>
    <div class="row flex-md-row">
      <div class="col p-6 d-flex flex-column">
        <div class="top-product__orders">
          <div class="top-product__orders__title">Купили более</div>
          <div class="top-product__orders__value">{{ product.orders }} раз</div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-6">
            <div>Оборот:</div>
            <div class="text-success">~{{ product.revenueStr }}</div>
          </div>
          <div class="col-lg-6">
            <div>Отзывы:</div>
            <div class="text-success">{{ product.reviews }} шт.</div>
          </div>
        </div>
        <div class="top-product__category">Категория: {{ product.category.vpath }}</div>
      </div>
      <div class="col p-6 d-flex flex-column">
        <div class="top-product__image">
          <div class="top-product__image__container">
            <img :src="product.firstImage" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product'],
  computed: {
    externalLink() {
      return `https://www.wildberries.ru/catalog/${this.product.sku}/detail.aspx`;
    },
    getServiceImage() {
      // eslint-disable-next-line import/no-dynamic-require,global-require
      return require(`@/assets/img/mps/mp_${this.product.service}.png`);
    },
  },
};
</script>
