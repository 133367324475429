<template>
  <div class="page-card last-orders bg-white mt-4">
    <title-with-month-select v-model="date" @change="changeMonth">
      <h2 class="title title_fz24 mb-2">Динамика общих продаж</h2>
      <div class="page-subtitle mb-2">по дате, магазин <span>"Название"</span></div>
    </title-with-month-select>
    <data-table
      ref="itemsTable"
      :head-filters="false"
      columns-url="/dashboard/last-orders-columns"
      rows-url="/dashboard/last-orders"
      :base-query-params="baseQueryParams"
    >
    </data-table>
  </div>
</template>

<script>
import TitleWithMonthSelect from '@/components/TitleWithMonthSelect.vue';
import DataTable from '@/components/dataTable/DataTable.vue';

export default {
  components: { DataTable, TitleWithMonthSelect },
  data() {
    return {
      date: this.$moment().format('MM.YYYY'),
    };
  },
  computed: {
    baseQueryParams() {
      return { date: this.date };
    },
  },
  methods: {
    changeMonth() {
      this.$nextTick(() => {
        this.$refs.itemsTable.loadItems(true);
      });
    },
  },
  mounted() {
    this.$refs.itemsTable.loadItems();
    this.$refs.itemsTable.loadColumns();
  },
};
</script>
