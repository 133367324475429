<template>
  <ValidationProvider
    :rules="config.rules"
    :ref="field"
    :name="config.labelText"
    :vid="field"
    v-slot="{ errors }"
    slim
  >
    <input
      trim
      type="text"
      class="form-control"
      :id="fieldId"
      :name="field"
      :placeholder="inputPlaceholder"
      @input="onInput"
      :value="config.value"
      v-bind="inputBinds"
      :class="{ 'is-invalid': !!errors.length }"
    />
  </ValidationProvider>
</template>

<script>
import fieldMixin from './fieldMixin';

export default {
  mixins: [fieldMixin],
  computed: {
    inputBinds() {
      const props = JSON.parse(JSON.stringify(this.$props));
      delete props.config;
      return props;
    },
  },
};
</script>
