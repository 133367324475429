<template>
  <div class="table-menu__left">
    <div
      class="table-menu__popup-wrap table-menu__popup-wrap_filters"
      v-click-outside="closeFilters"
    >
      <button
        class="btn btn-outline-secondary fw-normal btn-filter-dropdown btn-filters-select"
        @click.prevent="toggleFilters"
      >
        <i class="fa-solid fa-filter d-inline d-sm-none"></i>
        <span class="d-none d-sm-inline">Фильтры</span>
      </button>
      <button class="btn btn-primary ms-2" @click="applyFilters">Сохранить</button>
      <div v-show="allOpened">
        <div class="table-filters">
          <div class="table-filters__top-actions">
            <a href="#" @click.prevent="closeFilters">
              <i class="fas fa-arrow-left"></i>
            </a>
            Фильтры
            <a href="#" @click.prevent="closeFilters">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <div class="table-filters__items">
            <template v-for="(filter, index) in computedFilters">
              <single-filter
                :filter="filter"
                :opened="filterIsOpened(index)"
                @toggle="toggleFilter(index)"
                @select-filter="onSelectFilter"
                :key="`datatable_filter_${index}`"
              ></single-filter>
            </template>
          </div>
          <div class="table-filters__actions">
            <a href="#" class="me-auto" @click.prevent="clearFilters">Очистить</a>
            <a href="#" @click.prevent="closeFilters">Применить</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleFilter from './SingleFilter.vue';

const rangeColumns = ['range', 'date'];

export default {
  props: {
    filters: Array,
  },
  components: {
    SingleFilter,
  },
  data() {
    return {
      selectedFilters: {},
      filtersOpened: {},
      allOpened: false,
    };
  },
  computed: {
    computedFilters: {
      cache: false,
      get() {
        return this.filters.map((filter) => {
          const defaultValue = rangeColumns.includes(filter.type) ? [null, null] : null;
          return {
            ...filter,
            value: this.selectedFilters[filter.field] || defaultValue,
          };
        });
      },
    },
  },
  methods: {
    clearFilters() {
      this.$set(this, 'selectedFilters', {});
    },
    applyFilters() {
      this.$emit('set-filters', this.selectedFilters);
    },
    onSelectFilter({ field, value }) {
      this.filtersOpened = {};
      this.$set(this.selectedFilters, field, value);
    },
    closeFilters() {
      if (this.allOpened) {
        this.allOpened = false;
        this.filtersOpened = {};
      }
    },
    toggleFilters() {
      this.allOpened = !this.allOpened;
      if (!this.allOpened) {
        this.filtersOpened = {};
      }
    },
    toggleFilter(index) {
      const isOpened = !!this.filtersOpened[index];
      this.filtersOpened = {};
      if (!isOpened) {
        this.filtersOpened[index] = true;
      }
    },
    filterIsOpened(index) {
      return typeof this.filtersOpened[index] !== 'undefined' && !!this.filtersOpened[index];
    },
    filterComponent(filter) {
      const type = filter.type || 'text';
      return `filter-${type}`;
    },
    handleCloseFiltersEvent() {
      this.filtersOpened = {};
    },
  },
};
</script>
