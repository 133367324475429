export default {
  1: 'etc',
  258: 'home',
  306: 'female',
  481: 'toy',
  519: 'books',
  543: 'cosmetics',
  566: 'male',
  629: 'shoes',
  784: 'sport',
  1023: 'jewelry',
  4830: 'laptop',
  4863: 'garden',
  5486: 'pen-file',
  6119: 'pets',
  6994: 'car',
  10296: 'apple',
  10326: 'health',
  16107: 'washing-machine',
  17006: 'paint',
  62057: 'gender',
  62827: 'furniture',
  130255: 'kremlin',
};
