<template>
  <section :class="sectionClasses">
    <div class="amount__top mb-2 d-flex">
      <div class="d-flex align-items-center flex-wrap w-100">
        <h2 class="title title_fz24">
          <span>{{ pageName }}</span> по складам и размерам на {{ viewDate }}
        </h2>
        <div class="ms-auto" v-if="needShowHistoryButton">
          <button class="btn btn-primary btn-lg">История заказов</button>
        </div>
      </div>
    </div>
    <div class="amount__main">
      <div class="amount__chart">
        <div class="amount__chart-body">
          <canvas :id="canvasId"></canvas>
        </div>
      </div>
      <div class="table__wrapper table-responsive amount__table">
        <table class="table table-borderless">
          <thead>
            <tr class="table-bordered">
              <th scope="col">
                Склад
                <form class="input-group">
                  <search-input
                    placeholder="Фильтр"
                    v-model="filters.stock"
                    @done="filter('stock', $event)"
                  ></search-input>
                </form>
              </th>
              <th scope="col">
                {{ tableHeadLabel }} (шт)
                <form class="input-group">
                  <search-input
                    placeholder="Фильтр"
                    v-model="filters.value"
                    @done="filter('value', $event)"
                  ></search-input>
                </form>
              </th>
              <th scope="col">
                {{ tableHeadLabel }} (%)
                <form class="input-group">
                  <search-input
                    placeholder="Фильтр"
                    v-model="filters.valuePercent"
                    @done="filter('valuePercent', $event)"
                  ></search-input>
                </form>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stock, index) in filteredStocks" :key="`stock_${index}`">
              <td>
                <span>{{ stock.name }}</span>
              </td>
              <td>{{ stock.value }}</td>
              <td>{{ stock.valuePercent }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><span>Итого</span></td>
              <td>
                <span>{{ total }}</span>
              </td>
              <td>
                <export-excel
                  v-if="filteredStocks && filteredStocks.length"
                  :data="filteredStocks"
                  :fields="fieldsForExport"
                  class="me-2"
                  name="data.xls"
                >
                  <button class="btn btn-secondary hide-800">Сохранить отчет</button>
                </export-excel>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <button class="btn btn-secondary btn_tac">Сохранить отчет</button>
  </section>
</template>

<script>
import SearchInput from '@/components/form/SearchInput.vue';
import ChartMixin from '@/mixins/ChartMixin';

export default {
  props: ['product', 'sectionClass'],
  mixins: [ChartMixin],
  components: { SearchInput },
  data() {
    return {
      filterStock: null,
      filterValue: null,
      filterValuePercent: null,
      filters: {
        stock: null,
        value: null,
        valuePercent: null,
      },
      pageName: 'Остатки',
      tableHeadLabel: 'Остаток',
      canvasId: 'stocksRemains',
      needShowHistoryButton: false,
      date: null,
      chartData: {
        labels: [],
        data: [],
        stocks: [],
      },
      stocks: [],
      total: null,
    };
  },
  computed: {
    fieldsForExport() {
      const fields = {};
      fields['Склад'] = 'name';
      // noinspection JSNonASCIINames
      fields[`${this.tableHeadLabel} (шт)`] = 'value';
      fields[`${this.tableHeadLabel} (%)`] = 'valuePercent';
      return fields;
    },
    filteredStocks() {
      return this.stocks.filter((stock) => {
        if (!this.filters.stock && !this.filters.value && !this.filters.valuePercent) {
          return true;
        }
        return (
          (this.filters.stock &&
            stock.name.toLowerCase().includes(this.filters.stock.toLowerCase())) ||
          (this.filters.value &&
            stock.rawValue.toString().toLowerCase().includes(this.filters.value.toLowerCase())) ||
          (this.filters.valuePercent &&
            stock.valuePercent
              .toString()
              .toLowerCase()
              .includes(this.filters.valuePercent.toLowerCase()))
        );
      });
    },
    sectionClasses() {
      const classes = ['amount'];
      if (this.sectionClass) {
        classes.push(this.sectionClass);
      }
      return classes.join(' ');
    },
    dataUrl() {
      return `/analytics/goods/stocks-remains?id=${this.product.sku}`;
    },
    bgColors() {
      const colors = [];
      for (let i = 0, iMax = this.chartData.labels.length; i < iMax; i++) {
        colors.push(
          `rgb(${this.$randomNumber(0, 255)}, ${this.$randomNumber(0, 255)}, ${this.$randomNumber(
            0,
            255,
          )})`,
        );
      }
      return colors;
    },
    viewDate() {
      if (!this.date) {
        return null;
      }
      return this.$moment(this.date).format('DD.MM.YYYY');
    },
    chartCanvas() {
      return document.getElementById(this.canvasId);
    },
    chartConfig() {
      return {
        type: 'doughnut',
        data: {
          labels: this.chartData.labels,
          datasets: [
            {
              label: '',
              data: this.chartData.data,
              backgroundColor: this.bgColors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          aspectRatio: 2,
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
          },
          plugins: {
            legend: {
              display: true,
              position: 'right',
            },
          },
          layout: {
            padding: 0,
            autoPadding: false,
          },
        },
      };
    },
  },
  methods: {
    filter(field, value) {
      this.$set(this.filters, field, value);
    },
    getData() {
      this.$http.get(this.dataUrl).then((response) => {
        if (response.status === 200) {
          this.$set(this, 'chartData', response.data.chart);
          this.$set(this, 'stocks', response.data.stocks);
          this.$set(this, 'date', response.data.date);
          this.$set(this, 'total', response.data.total);
          this.$nextTick(() => {
            this.runChart();
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
