<template>
  <section class="product-stats">
    <div class="product-stats__wrapper">
      <div class="product-stats__item bg-white">
        <div class="product-stats__name">Заказов за сегодня</div>
        <div class="product-stats__num">{{ todayOrders }}</div>
        <div class="product-stats__percent">
          <span :class="diffCssClass(todayOrdersDiff)">
            {{ diffSymbol(todayOrdersDiff) }}{{ todayOrdersDiff }}%
          </span>
          по сравнению со вчера
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/1.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/1.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item bg-white">
        <div class="product-stats__name">Выручка за сегодня</div>
        <div class="product-stats__num">{{ todayRevenue }}</div>
        <div class="product-stats__percent">
          <span :class="diffCssClass(todayRevenueDiff)">
            {{ diffSymbol(todayRevenueDiff) }}{{ todayRevenueDiff }}%
          </span>
          по сравнению со вчера
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/2.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/2.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item bg-white">
        <div class="product-stats__name">Заказов за неделю</div>
        <div class="product-stats__num">{{ weekOrders }} шт</div>
        <div class="product-stats__percent">
          <span :class="diffCssClass(weekOrdersDiff)">
            {{ diffSymbol(weekOrdersDiff) }}{{ weekOrdersDiff }}%
          </span>
          {{ growthDecline(weekOrdersDiff) }} с прошлой
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/3.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/3.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item bg-white">
        <div class="product-stats__name">Выручка за неделю</div>
        <div class="product-stats__num">{{ weekRevenue }}</div>
        <div class="product-stats__percent">
          <span :class="diffCssClass(weekRevenueDiff)">
            {{ diffSymbol(weekRevenueDiff) }}{{ weekRevenueDiff }}%
          </span>
          {{ growthDecline(weekRevenueDiff) }} с прошлой
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/4.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/4.png" alt="" />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      todayOrders: 0,
      todayOrdersDiff: 0,
      weekOrders: 0,
      weekOrdersDiff: 0,
      todayRevenue: 0,
      todayRevenueDiff: 0,
      weekRevenue: 0,
      weekRevenueDiff: 0,
    };
  },
  methods: {
    growthDecline(value) {
      if (value === 0) {
        return '';
      }
      if (value < 0) {
        return 'спад';
      }
      return 'прирост';
    },
    diffCssClass(value) {
      if (value < 0) {
        return 'negative';
      }
      if (value === 0) {
        return 'neutral';
      }
      return null;
    },
    diffSymbol(value) {
      if (value <= 0) {
        return '';
      }
      return '+';
    },
    getData() {
      this.$store.commit('upRequests');
      this.$http.get('/dashboard/stats').then((response) => {
        this.$store.commit('downRequests');
        if (response.status === 200) {
          this.$set(this, 'todayOrders', response.data.today.orders.total);
          this.$set(this, 'todayOrdersDiff', response.data.today.orders.diff);
          this.$set(this, 'weekOrders', response.data.week.orders.total);
          this.$set(this, 'weekOrdersDiff', response.data.week.orders.diff);
          this.$set(this, 'todayRevenue', response.data.today.revenue.total);
          this.$set(this, 'todayRevenueDiff', response.data.today.revenue.diff);
          this.$set(this, 'weekRevenue', response.data.week.revenue.total);
          this.$set(this, 'weekRevenueDiff', response.data.week.revenue.diff);
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
