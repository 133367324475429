import Vue from 'vue';
import VueRouter from 'vue-router';

import BaseLayout from '@/components/layout/BaseLayout.vue';
import Dashboard from '@/pages/Dashboard.vue';
import Login from '@/pages/auth/Login.vue';
import Categories from '@/pages/analytics/categories/Categories.vue';
import Category from '@/pages/analytics/categories/Category.vue';
import Product from '@/pages/analytics/Product.vue';
import SearchProduct from '@/pages/analytics/product/SearchProduct.vue';
import CategoryCategories from '@/pages/analytics/categories/categoryCategories/CategoryCategories.vue';
import CategoryAnalysis from '@/pages/analytics/CategoryAnalysis.vue';
import OrdersMain from '@/pages/orders/OrdersMain.vue';
import OrdersSingle from '@/pages/orders/OrdersSingle.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
    component: BaseLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: Dashboard,
      },
      {
        path: '/analytics/categories',
        name: 'analytics:categories',
        component: Categories,
      },
      {
        path: '/analytics/search',
        name: 'analytics:search',
        component: SearchProduct,
      },
      {
        path: '/analytics/category/:categoryId/:page/:sku',
        name: 'analytics:product',
        component: Product,
      },
      {
        path: '/analytics/category/:categoryId/categories',
        name: 'analytics:category-categories',
        component: CategoryCategories,
      },
      {
        path: '/analytics/category/:categoryId/:page',
        name: 'analytics:category',
        component: Category,
      },
      {
        path: '/analytics/category-analysis',
        name: 'analytics:category-analysis',
        component: CategoryAnalysis,
      },
      {
        path: '/orders',
        name: 'orders',
        component: OrdersMain,
      },
      {
        path: '/orders/:order',
        name: 'orders:view',
        component: OrdersSingle,
      },
    ],
  },
  {
    path: '/auth/login',
    name: 'auth:login',
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
});

export default router;
