export default {
  props: ['filter'],
  data() {
    return {
      value: null,
    };
  },
  computed: {
    inputClass() {
      const classes = [];
      if (this.filter.inputClasses) {
        if (Array.isArray(this.filter.inputClasses)) {
          return [...classes, ...this.filter.inputClasses];
        }
        classes.push(this.filter.inputClasses);
      }
      return classes;
    },
    type() {
      return this.filter.type || 'text';
    },
    containerClass() {
      return `datatable-filters__${this.type}`;
    },
    inputProps() {
      return this.filter.inputProps || {};
    },
  },
  methods: {
    onSetValue() {
      this.$emit('set-value', this.value);
    },
    onInput(event) {
      this.$set(this, 'value', event.target.value);
    },
  },
};
