<template>
  <li class="nav-item sidebar__nav-item" :class="{ 'sidebar__nav-item_active': isActive }">
    <div class="sidebar__nav-icon">
      <i class="fa-solid fa-chart-pie"></i>
    </div>
    <div>
      <router-link
        :to="{ name: 'analytics:categories' }"
        class="nav-link sidebar__nav-link"
        active-class="white"
        exact
        >Категория:</router-link
      >
      <template v-if="needViewCategory">
        <menu-pages></menu-pages>
      </template>
      <template v-else>
        <div class="category-menu-item__label_category">Не выбрана</div>
      </template>
    </div>
  </li>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuPages from '@/pages/analytics/categories/sidebar/MenuPages.vue';

const activeRoutesActive = [
  'analytics:categories',
  'analytics:category',
  'analytics:product',
  'analytics:category-categories',
];

export default {
  components: { MenuPages },
  computed: {
    ...mapGetters({
      currentCategory: 'currentCategory',
    }),
    isActive() {
      return activeRoutesActive.includes(this.$route.name);
    },
    needViewCategory() {
      return !!this.currentCategory && this.isActive;
    },
  },
};
</script>
