<template>
  <aside class="sidebar d-none d-lg-block">
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid flex-column">
        <router-link exact :to="{ name: 'home' }">
          <img :src="getFrImage" class="logo" alt="" />
        </router-link>
        <div class="sidebar__title">{{ companyName }}</div>
        <!--<div class="sidebar__subtitle">Магазин “Название”</div>-->
        <!--        <form class="container-fluid input-group sidebar__search">-->
        <!--          <span class="sidebar__search-icon"></span>-->
        <!--          <input type="text" class="form-control sidebar__search-area" placeholder="Поиск" aria-label="Поиск">-->
        <!--        </form>-->
        <ul class="navbar-nav flex-column sidebar__nav">
          <li
            class="nav-item sidebar__nav-item"
            :class="{ 'sidebar__nav-item_active': homeLinkActive }"
          >
            <div class="sidebar__nav-icon">
              <i class="fa-solid fa-house"></i>
            </div>
            <router-link
              exact
              class="nav-link sidebar__nav-link"
              aria-current="page"
              :to="{ name: 'home' }"
            >
              Рабочий стол
            </router-link>
          </li>
          <menu-item></menu-item>
          <li
            class="nav-item sidebar__nav-item"
            :class="{ 'sidebar__nav-item_active': isLinkActive('analytics:category-analysis') }"
          >
            <div class="sidebar__nav-icon">
              <i class="fa-solid fa-bar-chart"></i>
            </div>
            <router-link
              exact
              class="nav-link sidebar__nav-link"
              aria-current="page"
              :to="{ name: 'analytics:category-analysis' }"
            >
              Анализ ниш
            </router-link>
          </li>
          <li
            v-if="ordersEnabled"
            class="nav-item sidebar__nav-item"
            :class="{
              'sidebar__nav-item_active': isLinkActive('orders') || isLinkActive('orders:view'),
            }"
          >
            <div class="sidebar__nav-icon">
              <i class="fa-solid fa-house"></i>
            </div>
            <router-link
              exact
              class="nav-link sidebar__nav-link"
              aria-current="page"
              :to="{ name: 'orders' }"
            >
              Мои заказы
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
</template>

<script>
import MenuItem from '@/pages/analytics/categories/sidebar/MenuItem.vue';

export default {
  components: { MenuItem },
  methods: {
    isLinkActive(name) {
      return this.$route.name === name;
    },
  },
  computed: {
    ordersEnabled() {
      return !!this.$store.getters.appConfig('orders_enabled');
    },
    homeLinkActive() {
      return this.$route.name === 'home';
    },
    caLinkActive() {
      return this.$route.name === 'category-analysis';
    },
    companyName() {
      if (!this.$auth.check()) {
        return '';
      }
      return this.$auth.user().department.company;
    },
    frId() {
      if (!this.$auth.check()) {
        return '';
      }
      return this.$auth.user().department.franchise_id;
    },
    getFrImage() {
      try {
        // eslint-disable-next-line import/no-dynamic-require,global-require
        return require(`@/assets/img/departTypes/${this.frId}/logo.png`);
      } catch (e) {
        // eslint-disable-next-line import/no-dynamic-require,global-require
        return require('@/assets/img/departTypes/1/logo.png');
      }
    },
  },
};
</script>
