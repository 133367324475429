export default {
  data() {
    return {
      formFields: {},
    };
  },
  comouted: {
    modalFields() {
      return {};
    },
  },
  methods: {
    setValue(field, value) {
      if (this.hasField(field)) {
        this.$set(this.formFields, field, value);
      }
    },
    getValue(field) {
      if (typeof this.formFields[field] === 'undefined') {
        return null;
      }
      return this.formFields[field];
    },
    clearFields() {
      this.$set(this, 'formFields', {});
    },
    hasField(fieldName) {
      return typeof this.modalFields[fieldName] !== 'undefined';
    },
  },
};
