<template>
  <div class="product-info__comment-form d-none d-lg-block">
    <label for="comment-form-text" class="product-info__comment-form-title"
      >Пользовательский комментарий:</label
    >
    <form @submit.prevent="sendComment">
      <textarea
        name="comment"
        id="comment-form-text"
        v-model="comment"
        class="product-info__comment-form-text"
        placeholder="Текст комментария"
      ></textarea>
      <button type="submit" class="btn btn-secondary">Сохранить комментарий</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ['product'],
  data() {
    return {
      comment: null,
    };
  },
  computed: {
    url() {
      return '/analytics/goods/comment';
    },
    requestConfig() {
      return {
        params: { id: this.product.sku },
      };
    },
  },
  created() {
    this.$http.get(this.url, this.requestConfig).then((response) => {
      if (response.status === 200) {
        this.comment = response.data;
      }
    });
  },
  methods: {
    sendComment() {
      this.$http.post(this.url, { comment: this.comment }, this.requestConfig).then((response) => {
        if (response.status === 200) {
          alert('Ваш комментарий сохранен');
        }
      });
    },
  },
};
</script>
