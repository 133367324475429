<template>
  <form class="input-group header__search d-lg-flex bg-white" @submit.prevent="onSubmit">
    <span class="sidebar__search-icon"></span>
    <input
      v-model="searchStr"
      type="text"
      name="top-search-input"
      id="top-search-input"
      class="form-control header__search-area"
      :placeholder="placeholder"
      :aria-label="placeholder"
    />
  </form>
</template>

<script>
export default {
  data() {
    return {
      searchStr: null,
    };
  },
  computed: {
    configFromStore() {
      return this.$store.getters.topSearchConfig;
    },
    placeholder() {
      return this.configFromStore.placeholder || 'Поиск по SKU';
    },
  },
  methods: {
    onSubmit() {
      const searchStr = this.searchStr.trim();
      const fromStore = this.configFromStore.onSubmitFn;
      if (typeof fromStore === 'function') {
        fromStore(searchStr);
      } else if (searchStr) {
        this.$router
          .push({
            name: 'analytics:search',
            query: { sku: searchStr },
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    if (this.$route.query.sku) {
      this.searchStr = this.$route.query.sku;
    }
  },
  watch: {
    searchStr(v) {
      this.$store.commit('setSearchString', v);
    },
    $route(route) {
      if (route.name !== 'analytics:search' && route.name !== 'analytics:categories') {
        this.searchStr = null;
      }
    },
  },
};
</script>
