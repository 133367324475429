<template>
  <div class="date-range-button">
    <date-range-picker
      @select="onInput"
      opens="left"
      :auto-apply="true"
      :locale-data="locale"
      :ranges="ranges"
      :date-range="value"
    ></date-range-picker>
  </div>
</template>

<script>
// noinspection NonAsciiCharacters
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          startDate: null,
          endDate: null,
        };
      },
    },
  },
  methods: {
    onInput(values) {
      this.$emit('input', values);
    },
  },
  computed: {
    ranges() {
      const endDate = this.$moment().subtract(1, 'days').toDate();
      // noinspection NonAsciiCharacters
      const ranges = {
        Сегодня: [this.$moment().startOf('day').toDate(), this.$moment().toDate()],
        Вчера: [
          this.$moment().subtract(1, 'day').startOf('day').toDate(),
          this.$moment().subtract(1, 'day').endOf('day').toDate(),
        ],
      };
      const days = [7, 30, 90, 180, 365];
      for (let i = 0; i < days.length; i++) {
        // noinspection JSNonASCIINames
        ranges[`${days[i]} дней`] = [
          this.$moment().subtract(days[i], 'day').startOf('day').toDate(),
          endDate,
        ];
      }
      return ranges;
    },
  },
  data() {
    return {
      locale: {
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: 'Применить',
        cancelLabel: 'Отмена',
        weekLabel: 'Нед',
        customRangeLabel: 'Свой выбор',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: [
          'Янв',
          'Вев',
          'Мар',
          'Апр',
          'Май',
          'Июн',
          'Июл',
          'Авг',
          'Сен',
          'Окт',
          'Ноя',
          'Дек',
        ],
        firstDay: 1,
      },
    };
  },
};
</script>
