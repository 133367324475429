<template>
  <div class="card item">
    <div class="item__body">
      <h2 class="item__note">Заказ №</h2>
      <p class="item__num">{{order.id}}</p>
      <div class="item__comment">
        <h3 class="item__comment-title">Комментарий</h3>
        <p class="item__comment-text">{{order.description}}</p>
      </div>
    </div>
    <div class="item__item-img">
      <img src="../../../assets/img/order-example.jpg" alt="" />
    </div>
    <div class="item__chars">
      <div class="item__char">
        <h3 class="item__char-title">Дата заказа:</h3>
        <p class="item__char-text">{{orderDate}}</p>
      </div>
      <div class="item__char">
<!--        <h3 class="item__char-title">Дата доставки:</h3>-->
<!--        <p class="item__char-text">15.06.2023</p>-->
      </div>
      <div class="item__char">
        <h3 class="item__char-title">Затрат по заказу:</h3>
        <p class="item__char-text">{{toDecimal(order.totalSum)}} руб.</p>
      </div>
      <div class="item__char">
        <h3 class="item__char-title">Ожидаемая прибыль:</h3>
        <p class="item__char-text">{{toDecimal(order.expectedProfit)}} руб.</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import decimal from 'vue-good-table/src/components/types/decimal';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderDate() {
      if (!this.order.date) {
        return null;
      }
      return moment(this.order.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
    },
  },
  methods: {
    toDecimal(value) {
      return decimal.format(value);
    },
  },
};
</script>
