<template>
  <ValidationProvider
    :rules="config.rules"
    :ref="field"
    :vid="field"
    :name="config.labelText"
    v-slot="{ errors }" slim>
    <select
      class="form-select"
      :id="fieldId"
      :name="field"
      @input="onInput"
      v-model="config.value"
      :class="{ 'is-invalid': errors.length }"
    >
      <option value="" disabled>Выберите:</option>
      <option v-for="(value, name) in config.options" :key="optionKey(name)" :value="name">
        {{ value }}
      </option>
    </select>
  </ValidationProvider>
</template>

<script>
import fieldMixin from './fieldMixin';

export default {
  mixins: [fieldMixin],
  methods: {
    optionKey(name) {
      return `option_${this.field}_${name}_key`;
    },
  },
};
</script>
