export default {
  state() {
    return {
      categories: [],
      currentCategory: null,
      allCategoriesOpen: false,
    };
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCurrentCategory(state, category) {
      state.currentCategory = category;
    },
    openAllCategories(state) {
      state.allCategoriesOpen = true;
    },
    closeAllCategories(state) {
      state.allCategoriesOpen = false;
    },
  },
  actions: {},
  getters: {
    categories: (state) => state.categories,
    currentCategory: (state) => state.currentCategory,
    allCategoriesOpened: (state) => state.allCategoriesOpen,
  },
};
