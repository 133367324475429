export default {
  methods: {
    checkUser(roles) {
      if (this.$auth.check('admin')) {
        return true;
      }
      if (!roles || !roles.length) {
        return false;
      }
      return this.$auth.check(roles);
    },
  },
};
