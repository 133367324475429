import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import auth from '@websanova/vue-auth/src/v2';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';
import VueGoodTablePlugin from 'vue-good-table';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';

import 'vue-good-table/dist/vue-good-table.css';
import moment from 'moment';
import excel from 'vue-excel-export';
import VueScrollTo from 'vue-scrollto';
import VueNativeSock from 'vue-native-websocket';

import './assets/scss/new.scss';

import Moment from '@/plugins/moment';
import randomNumber from '@/plugins/randomNumber';
import clickOutside from '@/directives/clickOutside';
import relativeTime from '@/directives/relativeTime';

import AppLoader from '@/components/AppLoader.vue';
import router from './router';
import store from './store';

import App from './components/App.vue';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('url', {
  validate: (value) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(value);
  },
  message: 'Неверный формат URL',
});

localize('ru', ru);

Vue.config.productionTip = false;
Vue.use(VueGoodTablePlugin);
Vue.use(VueAxios, axios);
Vue.use(excel);
Vue.use(VueScrollTo);
Vue.use(Moment);
Vue.use(randomNumber);
Vue.use(
  VueNativeSock,
  process.env.VUE_APP_WS_URL || `//${window.location.host}/ws`,
  {
    format: 'json',
    reconnection: true,
    reconnectionDelay: 3000,
  },
);

Vue.directive('click-outside', clickOutside);
Vue.directive('relative-time', relativeTime);

Vue.component('date-range-picker', DateRangePicker);
Vue.component('app-loader', AppLoader);

Vue.use(auth, {
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    authRedirect: { name: 'auth:login' },
    forbiddenRedirect: { path: '/auth/forbidden' },
    notFoundRedirect: { path: '/404' },
    refreshData: {
      enabled: false,
    },
    loginData: {
      url: '/profile/auth/login',
      fetchUser: true,
    },
    logoutData: {
      url: '/auth/logout',
      makeRequest: true,
    },
    fetchData: {
      url: '/profile/auth/user',
      makeRequest: false,
    },
    parseUserData(data) {
      if (data.franchise && data.franchise.name) {
        document.title = data.franchise.name;
      }
      return data;
    },
  },
});

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || '/api';

// axios.interceptors.response.use(
//   (response) => {
//     store.commit('downRequests');
//     return response;
//   },
//   (error) => {
//     store.commit('downRequests');
//     return Promise.reject(error);
//   },
// );
// axios.interceptors.request.use(
//   (config) => {
//     store.commit('upRequests');
//     return config;
//   },
//   (error) => {
//     store.commit('downRequests');
//     return Promise.reject(error);
//   },
// );

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.filter('date', (value) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
  return null;
});
Vue.filter('datetime', (value) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY в hh:mm:ss');
  }
  return null;
});

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  el: '#app',
  router,
  store,
});
