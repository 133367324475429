<template>
  <div id="product-similar">
    <div class="similar__top mt-4 mb-2 d-flex flex-wrap">
      <div class="similar__top-item">
        <h2 class="title title_fz24">Похожие товары</h2>
        <div class="similar__subtitle">
          Товары, которые отображаются в блоке Похожие товары на Wildberries
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" href="#">Похожие товары</a>
      </li>
    </ul>
    <data-table
      ref="similarTable"
      container-class="product-similar-table"
      :columns-url="columnsUrl"
      :rows-url="rowsUrl"
      :base-query-params="baseQueryParams"
    >
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/dataTable/DataTable.vue';

export default {
  components: { DataTable },
  props: ['product'],
  data() {
    return {
      products: [],
    };
  },
  computed: {
    baseQueryParams() {
      return {
        similar: this.product.sku,
      };
    },
    columnsUrl() {
      return '/analytics/goods/columns?forSimilar=1';
    },
    rowsUrl() {
      return '/analytics/goods/index';
    },
  },
  methods: {
    update() {
      this.$refs.similarTable.loadColumns(true);
      this.$refs.similarTable.loadItems(true);
    },
  },
  mounted() {
    this.update();
  },
};
</script>
