<template>
  <div class="d-flex flex-wrap justify-content-between">
    <div>
      <slot></slot>
    </div>
    <select
      class="form-select sales-dynamics__date-select align-self-start"
      :Value="value"
      @change="emitInput"
    >
      <option
        v-for="(view, optionValue) in datesList()"
        :key="`date-select_${optionValue}`"
        :value="optionValue"
      >
        {{ view }}
      </option>
    </select>
  </div>
</template>

<script>
import datesSelectMixin from '@/mixins/datesSelectMixin';

export default {
  mixins: [datesSelectMixin],
  props: ['value'],
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    emitInput(event) {
      this.$emit('change', event.target.value);
    },
  },
};
</script>
