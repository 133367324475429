<template>
  <div class="signin-container d-flex align-items-center py-4 bg-body-tertiary">
    <main class="form-signin w-100 m-auto">
      <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <template v-if="!rememberedUserFio">
            <validation-provider
              vid="email"
              name="E-mail"
              :rules="{ required: !rememberedUserFio, email: true }"
              v-slot="{ errors }"
            >
              <div class="form-floating">
                <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': !!errors.length }"
                  id="email"
                  name="email"
                  placeholder="Логин"
                  v-model="form.email"
                />
                <label for="email">Логин</label>
                <div class="invalid-feedback" v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
          </template>
          <div class="form-group" v-else>
            <p class="mb-0">С возвращением, {{ rememberedUserFio }}</p>
            <p>(<a href="#" @click.prevent="unremember">Это не я</a>)</p>
          </div>
          <validation-provider vid="password" name="Пароль" rules="required" v-slot="{ errors }">
            <div class="form-floating">
              <input
                type="password"
                class="form-control"
                :class="{ 'is-invalid': !!errors.length, remembered: !!rememberedUserFio }"
                id="password"
                placeholder="Пароль"
                v-model="form.password"
              />
              <label for="password">Пароль</label>
              <div class="invalid-feedback" v-if="errors.length">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <div class="form-check text-start my-3">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="staySignedIn"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault"> Оставаться в системе </label>
          </div>
          <button class="btn btn-primary w-100 py-2" type="submit">Войти</button>
        </form>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import formMixin from '../../mixins/formMixin';

const defaultForm = {
  email: '',
  password: '',
};

export default {
  mixins: [formMixin],
  data() {
    return {
      observerRef: 'loginForm',
      form: JSON.parse(JSON.stringify(defaultForm)),
      staySignedIn: true,
    };
  },
  methods: {
    unremember() {
      this.$auth.unremember();
    },
    onSubmit() {
      const data = {
        password: this.form.password,
        email: this.form.email,
      };
      if (this.rememberedUserEmail && !this.form.email) {
        data.email = this.rememberedUserEmail;
      }
      this.$auth
        .login({
          data,
          staySignedIn: !!this.staySignedIn,
          fetchUser: false,
          redirect: { name: 'home' },
        })
        .then((response) => {
          this.validateFromResponse(response);
          if (response.status === 200) {
            if (response.data.email && response.data.fio) {
              this.$auth.remember(
                JSON.stringify({ email: response.data.email, fio: response.data.fio }),
              );
            }
          }
        });
    },
  },
  computed: {
    rememberedUserFio() {
      const { rememberData } = this;
      if (rememberData && rememberData.fio) {
        return rememberData.fio;
      }
      return null;
    },
    rememberedUserEmail() {
      const { rememberData } = this;
      if (rememberData && rememberData.email) {
        return rememberData.email;
      }
      return null;
    },
    rememberData() {
      const rememberStr = this.$auth.remember();
      if (!rememberStr) {
        return null;
      }
      return JSON.parse(rememberStr);
    },
  },
  created() {
    if (this.$auth.check()) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
.signin-container {
  height: 100vh;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

#email {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#password {
  margin-bottom: 10px;
  &:not(.remembered) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

/*.invalid-tooltip {*/
/*  top: 0;*/
/*  right: calc(-100% - 10px);*/
/*  opacity: .9;*/
/*}*/
</style>
