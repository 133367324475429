<template>
  <div class="table-filter" :class="{ 'table-filter_opened': opened }">
    <div class="vgt-head-filter-wrap" v-show="opened">
      <div class="vgt-head-filter">
        <div class="table-filters__top-actions table-filters__top-actions-applied">
          <a href="#" @click.prevent="toggle">
            <i class="fas fa-arrow-left"></i>
          </a>
          {{ filter.label }}
          <a href="#" @click.prevent="closeFilters">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="vgt-head-filter__component">
          <component
            ref="filterComponent"
            :is="componentName"
            :filter="filter"
            @set-value="onSetValue"
          ></component>
        </div>
      </div>
    </div>
    <div class="table-filter__row d-flex" @click.prevent="toggle">
      <div class="table-filter__action">
        <div class="table-filter__action-inner">
          <div class="table-filter__show">
            <i class="fas fa-angle-left" v-show="opened"></i>
            <i class="fas fa-plus" v-show="!opened"></i>
          </div>
        </div>
      </div>
      <div class="table-filter__column me-auto">
        <div class="table-filter__column-inner">
          <p class="table-filter__name">
            {{ filter.label }}
          </p>
        </div>
      </div>
      <div class="table-filter__value">
        <div class="table-filter__value-inner">{{ viewValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterDate from './components/FilterDate.vue';
import FilterNumber from './components/FilterNumber.vue';
import FilterRange from './components/FilterRange.vue';
import FilterText from './components/FilterText.vue';

const rangeColumns = ['range', 'date'];

export default {
  props: ['filter', 'opened'],
  components: {
    FilterDate,
    FilterNumber,
    FilterRange,
    FilterText,
  },
  computed: {
    componentName() {
      return `filter-${this.filter.type || 'text'}`;
    },
    viewValue() {
      if (rangeColumns.includes(this.filter.type)) {
        if (this.filter.value[0] !== null && this.filter.value[0] !== '') {
          let max = this.filter.value[1];
          if (!max) {
            max = 'ထ';
          }
          return `${this.filter.value[0]} - ${max}`;
        }
        return null;
      }
      return this.filter.value;
    },
  },
  methods: {
    closeFilters() {
      this.$parent.closeFilters();
    },
    onSetValue(v) {
      this.$emit('select-filter', {
        field: this.filter.field,
        value: v,
      });
    },
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>
