<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h2 class="title title_fz24 my-4">Топ-товар по продажам</h2>
        <top-product :product="bySales" v-if="bySales"></top-product>
      </div>
      <div class="col-lg-6">
        <h2 class="title title_fz24 my-4">Топ-товар по выручке за 7 дней</h2>
        <top-product :product="byRevenue" v-if="byRevenue"></top-product>
      </div>
    </div>
  </div>
</template>

<script>
import TopProduct from './TopProduct.vue';

export default {
  components: { TopProduct },
  data() {
    return {
      bySales: null,
      byRevenue: null,
    };
  },
  methods: {
    getData() {
      this.$store.commit('upRequests');
      this.$http.get('/dashboard/top-products').then((response) => {
        this.$store.commit('downRequests');
        if (response.status === 200) {
          this.$set(this, 'bySales', response.data.bySales);
          this.$set(this, 'byRevenue', response.data.byRevenue);
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
