<template>
  <div class="category">
    <div class="d-flex mb-2">
      <div class="header-title">
        <slot name="header-title"></slot>
        <slot name="header-subtitle"></slot>
      </div>
      <div class="filter-container ms-auto">
        <div class="d-flex align-items-center">
          <span class="me-2">Выбран период:</span>
          <div class="filter-calendar" style="pointer-events: none">
            <date-range-picker-button v-model="dates"></date-range-picker-button>
          </div>
        </div>
      </div>
    </div>
    <data-table
      ref="itemsTable"
      :id="tableId"
      selectable
      exportable
      :head-filters="hasHeadFilters"
      :filters="filtersConfig"
      :columns-url="columnsUrl"
      :rows-url="rowsUrl"
      :base-query-params="baseQueryParams"
    >
      <template #top-left>
        <h2 class="title title_fz24">Анализ по всем категориям</h2>
        <component :is="TopLeftPriceSegment"></component>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/dataTable/DataTable.vue';
import datesDiffMixin from '@/mixins/datesDiffMixin';
import DateRangePickerButton from '@/components/form/DateRangePickerButton.vue';
import TopLeftCategoryAnalysis from './categories/categoryComponents/TopLeftCategoryAnalysis.vue';

export default {
  components: { DateRangePickerButton, DataTable, TopLeftCategoryAnalysis },
  mixins: [datesDiffMixin],
  data() {
    return {
      hasHeadFilters: true,
      rows: [],
      columns: [],
      totalRecords: 0,
      tableFilters: {
        enabled: true,
        filters: [],
      },
      dates: {
        startDate: this.$moment().subtract(30, 'days').toDate(),
        endDate: this.$moment().subtract(1, 'days').toDate(),
      },
    };
  },
  computed: {
    tableId() {
      return 'category_category-analysis';
    },
    TopLeftPriceSegment() {
      return TopLeftCategoryAnalysis;
    },
    filtersConfig() {
      return {
        enabled: this.tableFilters.enabled,
        filters: this.tableFilters.filters,
      };
    },
    columnsUrl() {
      return '/analytics/category-analysis/columns';
    },
    rowsUrl() {
      return '/analytics/category-analysis/index';
    },
    baseQueryParams() {
      const params = {
        category: 0,
        service: this.currentCategory ? this.currentCategory.vservice : null,
        dates: {},
      };
      if (this.dates) {
        if (this.dates.startDate) {
          params.dates.startDate = this.$moment(this.dates.startDate).format('DD.MM.YYYY 00:00:00');
        }
        if (this.dates.endDate) {
          params.dates.endDate = this.$moment(this.dates.endDate).format('DD.MM.YYYY 23:59:59');
        }
      }
      return params;
    },
  },
  methods: {
    getFilters() {
      this.$http.get('/analytics/category-analysis/filters').then((response) => {
        if (response.status === 200) {
          if (response.data !== false) {
            this.$set(this, 'hasHeadFilters', true);
            this.$set(this.tableFilters, 'enabled', true);
            this.$set(this.tableFilters, 'filters', response.data);
          } else {
            this.$set(this, 'hasHeadFilters', false);
            this.$set(this.tableFilters, 'enabled', false);
          }
        }
      });
    },
    afterRoute() {
      this.$refs.itemsTable.loadColumns(true);
      this.$refs.itemsTable.loadItems(true);
      this.getFilters();
    },
    afterFetch() {
      this.$refs.itemsTable.loadColumns();
      this.$refs.itemsTable.loadItems();
      this.getFilters();
    },
  },
  created() {
    this.$nextTick(() => {
      this.afterFetch();
    });
  },
  watch: {
    dates(v, ov) {
      if (v && (!ov || ov !== v)) {
        this.$nextTick(() => {
          this.afterRoute();
        });
      }
    },
  },
};
</script>
