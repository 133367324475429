import Vue from 'vue';
import Vuex from 'vuex';

import categories from './modules/categories';
import orders from './modules/orders';

Vue.use(Vuex);

const topSearchDefaultConfig = {
  placeholder: null,
  onSubmitFn: null,
  searchString: null,
};

export default new Vuex.Store({
  state: {
    requestsCount: 0,
    appConfig: {},
    topSearchConfig: JSON.parse(JSON.stringify(topSearchDefaultConfig)),
  },
  mutations: {
    setSearchString(state, value) {
      state.topSearchConfig.searchString = value;
    },
    setTopSearchConfig(state, config) {
      state.topSearchConfig = config;
    },
    setTopSearchDefault(state) {
      state.topSearchConfig = JSON.parse(JSON.stringify(topSearchDefaultConfig));
    },
    upRequests(state) {
      state.requestsCount += 1;
    },
    downRequests(state) {
      if (state.requestsCount > 0) {
        state.requestsCount -= 1;
      }
    },
    setAppConfig(state, config) {
      state.appConfig = config;
    },
  },
  actions: {},
  getters: {
    searchString: (state) => state.topSearchConfig.searchString,
    topSearchConfig: (state) => state.topSearchConfig,
    requestsCount: (state) => state.requestsCount,
    appConfig: (state) => (key) => state.appConfig[key] || null,
    appConfig2: (state) => state.appConfig,
  },
  modules: {
    categories,
    orders,
  },
});
