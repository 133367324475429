export default {
  data() {
    return {
      categoryId: null,
      page: null,
      currentCategoryCache: null,
    };
  },
  computed: {
    currentCategory() {
      if (this.currentCategoryCache !== null) {
        return this.currentCategoryCache;
      }
      return this.$store.getters.currentCategory;
    },
    pageFromRoute() {
      return this.$route.params.page || null;
    },
    categoryIdFromRouteFromRoute() {
      return this.$route.params.page || null;
    },
  },
  methods: {
    fetchCategory() {
      if (!this.currentCategory) {
        this.$http
          .get('/analytics/category/view', {
            params: { id: this.categoryId },
          })
          .then((response) => {
            this.$set(this, 'currentCategoryCache', response.data);
            this.$store.commit('setCurrentCategory', response.data);
          });
      }
    },
    fromRoute(route) {
      if (route.params.page) {
        this.page = route.params.page;
      }
      if (route.params.categoryId) {
        this.categoryId = route.params.categoryId;
        this.fetchCategory();
        this.$nextTick(() => {
          this.afterRoute();
        });
      }
    },
    afterFetch() {},
    afterRoute() {},
  },
  mounted() {
    this.fromRoute(this.$route);
  },

  // destroyed() {
  //   this.$store.commit('setCurrentCategory', null);
  // },
  watch: {
    pageFromRoute(v, ov) {
      if (v && v !== ov) {
        this.page = v;
      }
    },
    categoryIdFromRouteFromRoute(v, ov) {
      if (v && v !== ov) {
        this.categoryId = v;
      }
    },
    $route(route) {
      this.fromRoute(route);
    },
  },
};
