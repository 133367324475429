<template>
  <div class="single-order" v-if="order">
    <h1 class="title title_fz24">Заказ №{{order.id}}</h1>
    <div class="single-order__wrapper">
      <div class="single-order__column">
        <OrdersSingleItem :order="order" :status="status" />
        <button class="btn single-order__btn" type="button" @click.prevent="openAddModal()">
          Добавить новый товар в заказ
        </button>
        <button
          v-if="canMoveToNextStatus"
          class="btn single-order__btn single-order__btn_green"
          @click.prevent="setNextStatus">
          {{status.button_name}}
        </button>
        <button
          v-if="canRevert"
          class="btn single-order__btn single-order__btn_green"
          @click.prevent="revert">
          Откатить
        </button>
      </div>
      <div class="single-order__column">
        <OrdersSingleActions ref="messages" :order="order" />
      </div>
    </div>
    <div class="mt-4">
      <data-table
        ref="orderListTable"
        exportable
        columns-url="/orders/product-columns"
        :row-actions="rowActions"
        :rows-url="productsUrl"
      >
      </data-table>
    </div>
    <form-modal
      title="Добавление заказа"
      :fields="modalFields"
      :is-opened="formModalOpened"
      @submit="onFormSave"
      @close="closeModal"
      @updateFormField="setValue"
    />
  </div>
</template>

<script>
import moment from 'moment';
import DataTable from '@/components/dataTable/DataTable.vue';
import FormModal from '@/components/modals/form/FormModal.vue';
import WsMixin from '@/mixins/WsMixin';
import checkUserMixin from '@/mixins/checkUserMixin';
import formModalMixin from '@/components/modals/form/formModalMixin';
import { pluralize } from 'pluralize-ru-ts';
import OrdersSingleItem from './components/OrdersSingleItem.vue';
import OrdersSingleActions from './components/OrdersSingleActions.vue';

export default {
  mixins: [checkUserMixin, formModalMixin, WsMixin],
  components: {
    OrdersSingleItem,
    OrdersSingleActions,
    FormModal,
    DataTable,
  },
  data() {
    return {
      formModalOpened: false,
      productIdForEdit: null,
      order: null,
      status: null,
      products: [],
      productsColumns: [],
      currencies: {},
    };
  },
  computed: {
    canMoveToNextStatus() {
      return this.checkUser(this.status.roles_edit) && this.status.next_status;
    },
    canRevert() {
      return this.checkUser(this.status.roles_revert) && this.status.id !== 1;
    },
    modalFields() {
      return {
        link: {
          labelText: 'Ссылка',
          inputPlaceholder: 'Ссылка на товар',
          validate: 'url',
          value: this.getValue('link'),
        },
        photo: {
          labelText: 'Загрузите фото',
          component: 'file-field',
          rules: 'image',
          value: this.getValue('photo'),
        },
        name: {
          labelText: 'Название',
          inputPlaceholder: 'Наименование товара, цвет, размер, подробно, как в карточке товара 1688',
          rules: 'required',
          value: this.getValue('name'),
        },
        currency_id: {
          labelText: 'Валюта поставщика',
          component: 'select-field',
          options: this.currencies,
          rules: 'required',
          value: this.getValue('currency_id'),
        },
        price: {
          labelText: 'Закупочная стоимость единицы товара (в валюте поставщика)',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('price'),
        },
        qt: {
          labelText: 'Количество товара',
          componentProps: {
            type: 'number',
            min: 1,
            step: 1,
          },
          rules: 'required|numeric',
          value: this.getValue('qt'),
        },
        yuan_rate: {
          labelText: 'Курс Юаня',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('yuan_rate'),
        },
        dollar_rate: {
          labelText: 'Курс Доллара',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('dollar_rate'),
        },
        logistics_costs: {
          labelText:
            'Логистические расходы по Китаю согласно данным от поставщика (в валюте поставщика), за партию',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('logistics_costs'),
        },
        weight: {
          labelText: 'Вес товара с упаковкой (в граммах, за ед)',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('weight'),
        },
        sell_price: {
          labelText: 'Цена продажи за единицу товара (Руб)',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('sell_price'),
        },
        fee_mp: {
          labelText: 'Комиссия маркетплейса (%)',
          componentProps: {
            type: 'number',
            min: 0,
            step: 0.01,
          },
          rules: 'required|double',
          value: this.getValue('fee_mp'),
        },
      };
    },
    orderId() {
      return this.$route.params.order;
    },
    productsUrl() {
      return `/orders/${this.orderId}/products`;
    },
    rowActions() {
      const $this = this;
      return [
        {
          name: 'delete',
          label: null,
          icon: 'fas fa-pencil',
          binds: {
            title: 'Редактировать',
          },
          action(row) {
            Object.keys(row).forEach((key) => {
              $this.setValue(key, row[key]);
            });
            $this.openAddModal(row.id);
          },
        },
      ];
    },
  },
  methods: {
    setStatus(status) {
      this.$http.put(`/orders/${this.orderId}/status`, { status }).then((response) => {
        this.onStatusUpdated(response);
      });
    },
    onStatusUpdated(response) {
      if (response.status === 200 && response.data && !response.data.errors) {
        this.getOrder();
        this.$refs.messages.getMessages();
        this.sendToWs('orders/message', {
          order: this.order,
          message: {
            message: `Заказ переведен в статус "${response.data.name}"`,
            username: this.$auth.user().fio,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            data: {
              type: 'status',
            },
          },
        });
        this.sendToWs('orders/status', {
          orderId: this.order.id,
          newStatusId: response.data.id,
        });
      }
    },
    setNextStatus() {
      this.setStatus(this.status.next_status);
    },
    revert() {
      if (!confirm('Откатить?')) {
        return;
      }
      this.$http.put(`/orders/${this.orderId}/revert`).then((response) => {
        this.onStatusUpdated(response);
      });
    },
    closeModal() {
      this.formModalOpened = false;
      this.productIdForEdit = null;
    },
    openAddModal(id = null) {
      this.formModalOpened = true;
      if (id) {
        this.productIdForEdit = id;
      }
    },
    onFormSave() {
      const formData = new FormData();
      Object.keys(this.formFields).forEach((key) => {
        formData.append(key, this.formFields[key]);
      });
      let url = `/orders/${this.orderId}/products`;
      let method = 'post';
      if (this.productIdForEdit) {
        url = `${url}/${this.productIdForEdit}`;
        method = 'put';
      }
      this.$http[method](url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response.data.errors) {
          this.$refs.orderListTable.addErrors(response.data.errors);
          return;
        }
        this.closeModal();
        this.getOrder().then(() => {
          this.$refs.orderListTable.loadItems();
          this.$refs.messages.getMessages();
          const pluralizeMe = pluralize('штуки', 'штук', 'штук');
          const { qt } = this.formFields;
          this.sendToWs('orders/message', {
            order: this.order,
            message: {
              message: `В заказ №${this.order.id} добавлен товар “${this.formFields.name}” в количестве ${qt} ${pluralizeMe(qt)}`,
              username: this.$auth.user().fio,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
          });
        });
      });
    },
    getOrder() {
      return this.$http.get(`/orders/${this.orderId}`).then((response) => {
        if (response.status === 200) {
          this.order = response.data.order;
          this.status = response.data.status;
        }
      });
    },
  },
  mounted() {
    this.getOrder().then(() => {
      this.$refs.orderListTable.loadColumns();
      this.$refs.orderListTable.loadItems();
    });
    this.$http.get('/orders/available-currencies').then((response) => {
      if (response.status === 200) {
        this.$set(this, 'currencies', response.data.currencies);
        if (typeof response.data.rates.USD !== 'undefined') {
          this.setValue('dollar_rate', response.data.rates.USD);
        }
        if (typeof response.data.rates.CNY !== 'undefined') {
          this.setValue('yuan_rate', response.data.rates.CNY);
        }
      }
    });
  },
};
</script>
