<template>
  <ul class="list-group" style="width: 100%">
    <li class="list-group-item list-group-item-secondary">
      <b>SPS</b><span>- Процент продавцов в категории, имеющих продажи</span>
    </li>
    <li class="list-group-item list-group-item-secondary">
      <b>SPB</b><span>- Процент брендов в категории, имеющих продажи</span>
    </li>
    <li class="list-group-item list-group-item-secondary">
      <b>SPP</b><span>- Процент товаров в категории, имеющих продажи</span>
    </li>
    <li class="list-group-item list-group-item-secondary">
      <b>TStC</b><span>- Ср. количество продаж, приходящееся на одного продавца</span>
    </li>
    <li class="list-group-item list-group-item-secondary">
      <b>TStS</b><span>- Ср. количество продаж, приходящееся на один товар</span>
    </li>
    <li class="list-group-item list-group-item-secondary">
      <b>Коэффициент Нейросети</b
      ><span
        >- Общий глобальный индекс по десятибальной шкале. Суммарный учет SPS, SPP, TStC и
        TStS</span
      >
    </li>
  </ul>
</template>

<script>
export default {};
</script>
