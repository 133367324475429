<template>
  <div class="card actions">
    <div class="actions__body" ref="messagesRef">
      <div
        v-for="(messages, date) in allMessages"
        :key="`order_${order.id}_msg_date_${date}`">
        <div class="actions__date"><p>{{getDate(date)}}</p></div>
        <message
          v-for="(message, index) in messages"
          :key="`order_${order.id}_message_${index}`"
          :message="message"
        ></message>
      </div>
    </div>
    <form class="actions__add" @submit.prevent="sendMessage">
      <input
        v-model="newMessage"
        type="text"
        class="actions__add-input"
        placeholder="Отправить сообщениеs" />
      <button class="btn actions__add-btn" type="submit">Отправить</button>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import WsMixin from '@/mixins/WsMixin';
import Message from './chat/Message.vue';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: { Message },
  mixins: [WsMixin],
  data() {
    return {
      messages: [],
      newMessage: '',
    };
  },
  computed: {
    allMessages() {
      const messages = {};
      this.messages.forEach((message) => {
        const date = message.date.split(' ')[0];
        if (typeof messages[date] === 'undefined') {
          messages[date] = [];
        }
        messages[date].push(message);
      });
      return messages;
    },
  },
  methods: {
    getDate(date) {
      const momentDate = moment(date, 'YYYY-MM-DD');
      if (momentDate.isSame(moment(), 'day')) {
        return 'Сегодня';
      }
      if (momentDate.isSame(moment().subtract(1, 'days'), 'day')) {
        return 'Вчера';
      }
      if (momentDate.isSame(moment().subtract(2, 'days'), 'day')) {
        return 'Позавчера';
      }
      if (momentDate.format('YYYY') !== moment().format('YYYY')) {
        return momentDate.format('DD MMMM YYYYг.');
      }
      return momentDate.format('dddd, DD MMMM');
    },
    onWsMessage(data) {
      if (data.action === 'orderMessage' && data.data.order === this.order.id) {
        this.addMessage(data.data.message);
      }
    },
    sendMessage() {
      const messageData = {
        message: this.newMessage,
        username: this.$auth.user().fio,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.$http.post(`/orders/${this.order.id}/messages`, { message: this.newMessage }).then((response) => {
        if (response.data === true) {
          this.sendToWs('orders/message', {
            order: this.order,
            message: messageData,
          });
          this.addMessage(messageData, true);
          this.newMessage = null;
        }
      });
    },
    addMessage(message, forceScroll = false) {
      this.messages.push(message);
      this.scrollMessages(forceScroll);
    },
    scrollMessages(force = false) {
      this.$nextTick(() => {
        // eslint-disable-next-line prefer-destructuring
        const messageContainer = this.$refs.messagesRef;
        const threshold = 100; // пикселей от низа для активации автоскролла
        const position = messageContainer.scrollTop + messageContainer.clientHeight;

        if (force || (messageContainer.scrollHeight - position < threshold)) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      });
    },
    getMessages() {
      this.$http.get(`/orders/${this.order.id}/messages`).then((response) => {
        if (response.status === 200) {
          this.$set(this, 'messages', response.data);
          this.scrollMessages(true);
        }
      });
    },
  },
  mounted() {
    this.getMessages();
  },
  updated() {
    this.scrollMessages();
  },
};
</script>
