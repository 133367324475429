export default function numberFormat(number, decimals = 2, decPoint = '.', thousandsSep = ' ') {
  if (isNaN(parseFloat(number)) || !isFinite(number)) {
    return NaN;
  }

  const newNumber = number.toFixed(decimals);

  const parts = newNumber.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);

  return parts.join(decPoint);
}
