<template>
  <div class="data-table" :class="containerClass">
    <div class="d-flex align-items-center flex-wrap mb-2" v-if="hasTopBlock">
      <div class="data-table__top-left py-1">
        <slot name="top-left"></slot>
      </div>
      <div
        class="ms-auto d-flex align-self-end py-1"
        v-if="tableColumns && tableColumns.length && filters.enabled"
      >
        <table-columns
          :id="id"
          :columns="computedColumns"
          @set-columns="onFilterColumns"
        ></table-columns>
        <filters :filters="computedFilters" @set-filters="setFilters"></filters>
      </div>
    </div>
    <vue-good-table
      v-if="tableColumns && tableColumns.length"
      :columns="tableColumns"
      :rows="rows"
      mode="remote"
      :pagination-options="{
        enabled: true,
      }"
      :style-class="tableClasses"
      :sort-options="sortOptions"
      :select-options="selectOptions"
      :total-rows="totalRecords"
      :row-style-class="rowStyleClass"
      :is-loading="isLoading"
      ref="dataTable"
      @on-row-click="onRowClick"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
    >
      <div slot="emptystate"></div>
      <template #loadingContent>Загрузка...</template>
      <template #after-rows>
        <slot name="after-rows"></slot>
      </template>
      <template #after-tbody>
        <slot name="after-tbody"></slot>
      </template>
      <template #pagination-bottom="props">
        <slot name="before-pagination"></slot>
        <vgt-pagination
          ref="dataTablePagination"
          mode="pagination"
          @page-changed="onPageChange"
          @per-page-changed="onPerPageChange"
          :custom-rows-per-page-dropdown="pagesDropdown"
          :paginate-dropdown-allow-all="false"
          :per-page-dropdown-enabled="false"
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        >
          <template #footer-left>
            <export-excel
              v-if="exportable && hasData"
              :data="rowsForExport"
              :fields="fieldsForExport"
              class="me-2"
              name="data.xls"
            >
              <button type="button" class="btn btn-primary">{{ exportButtonText }}</button>
            </export-excel>
            <template v-if="selectable">
              <button class="btn btn-secondary me-2" @click.prevent="selectAll">
                Выделить все
              </button>
              <button class="btn btn-secondary" @click.prevent="unselectAll">
                Снять выделение
              </button>
            </template>
          </template>
        </vgt-pagination>
      </template>
      <template #table-column="{ column }">
        <div class="label">{{ column.label }}</div>
      </template>
      <template #table-row="{ column, formattedRow, row, index }">
        <template v-if="!hasSlot('newRow')">
          <template v-if="column.type === 'image'">
            <img :src="value(column, formattedRow)" alt="thumb" />
          </template>
          <template v-else-if="column.field === 'sku' && row.service">
            <div class="d-flex align-items-center gap-1">
              <img :src="getServiceImagePath(row.service)" class="w-1rem" alt="mp" />
              <span>{{ formattedRow.sku }}</span>
            </div>
          </template>
          <template v-else-if="column.field === 'actions' && column.actions">
            <actions-column :index="index" :row="row" :actions="column.actions"></actions-column>
          </template>
          <template v-else>
            <div
              :title="needTruncate(column, formattedRow) ? value(column, formattedRow) : null"
              class="text-nowrap"
            >
              <template v-if="isLink(column)">
                <router-link :to="linkUrl(column, row)" :class="linkClass(column)" exact>{{
                  truncatedValue(column, formattedRow)
                }}</router-link>
              </template>
              <template v-else>
                {{ truncatedValue(column, formattedRow) }}
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <slot name="newRow" :column="column" :row="formattedRow"></slot>
        </template>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import VgtPagination from './pagination/VgtPagination.vue';
import Filters from './filters/Filters.vue';
import TableColumns from './TableColumns.vue';
import ActionsColumn from './ActionsColumn.vue';

const filterTypesMap = {
  number: 'range',
  decimal: 'range',
};

export default {
  components: {
    ActionsColumn,
    Filters,
    TableColumns,
    VgtPagination,
  },
  props: {
    id: String,
    columnsUrl: String,
    rowsUrl: String,
    columns: Array,
    rowActions: {
      type: Array,
    },
    exportable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    sortable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    baseQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    selectable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    headFilters: {
      type: Boolean,
      default() {
        return false;
      },
    },
    borderedHeader: {
      type: Boolean,
      default() {
        return true;
      },
    },
    filters: {
      type: Object,
      default() {
        return {
          enabled: false,
          filters: [],
        };
      },
    },
    tableClass: String,
    containerClass: [String, Array],
  },
  data() {
    return {
      rows: [],
      columnsFromResponse: null,
      totalRecords: 0,
      pagesDropdown: [5, 10, 20, 50, 100],
      selectedRows: {},
      hiddenColumns: [],
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: null,
        page: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    tableClasses() {
      const classes = ['vgt-table'];
      if (this.borderedHeader) {
        classes.push('bordered-header');
      }
      if (this.headFilters) {
        classes.push('has-filters');
      }
      if (this.tableClass) {
        classes.push(this.tableClass);
      }
      return classes.join(' ');
    },
    sortOptions() {
      return {
        enabled: this.sortable,
      };
    },
    hasTopBlock() {
      return (
        (this.tableColumns && this.tableColumns.length && this.filters.enabled) ||
        !!this.$slots['top-left']
      );
    },
    exportButtonText() {
      return Object.keys(this.selectedRows).length ? 'Экспорт выделенного' : 'Экспорт таблицы';
    },
    computedFilters() {
      if (
        this.filters.filters &&
        Array.isArray(this.filters.filters) &&
        this.filters.filters.length
      ) {
        return this.filters.filters;
      }
      if (!this.tableColumns || !this.tableColumns.length) {
        return [];
      }
      const filters = [];
      this.tableColumns.forEach((column) => {
        filters.push({
          field: column.field,
          label: column.label,
          type:
            typeof filterTypesMap[column.type] !== 'undefined' ?
              filterTypesMap[column.type] :
              'text',
        });
      });
      return filters;
    },
    hasData() {
      return !!this.rows.length;
    },
    selectOptions() {
      return {
        enabled: this.selectable,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true,
        selectionText: '',
      };
    },
    computedColumns() {
      let columns = [];
      if (this.columns) {
        columns = this.columns;
      } else {
        columns = this.columnsFromResponse;
      }
      return columns;
    },
    tableColumns() {
      const columns = this.computedColumns;
      if (!columns || !columns.length) {
        return null;
      }
      const newColumns = [];
      for (let i = 0, iMax = columns.length; i < iMax; i++) {
        if (!this.hiddenColumns.length || !this.hiddenColumns.includes(columns[i].field)) {
          const newColumn = columns[i];
          const tdClass = [];
          newColumn.filterOptions = {
            enabled: !!this.headFilters && newColumn.type !== 'image',
            trigger: 'enter',
            placeholder: 'Фильтр',
          };
          if (newColumn.type === 'image') {
            tdClass.push('td-img');
          }
          if (newColumn.fixed) {
            tdClass.push('fixed-column');
          }
          if (newColumn.success) {
            tdClass.push('success-column');
          }
          if (tdClass.length) {
            newColumn.tdClass = tdClass.join(' ');
          }
          newColumns.push(newColumn);
        }
      }
      if (this.rowActions && this.rowActions.length) {
        newColumns.push({
          field: 'actions',
          label: 'Действия',
          sortable: false,
          actions: this.rowActions,
        });
      }
      return newColumns;
    },
    rowsForExport() {
      const rows = Object.values(this.selectedRows);
      if (rows.length) {
        return rows;
      }
      return this.rows;
    },
    fieldsForExport() {
      const fields = {};
      for (let i = 0, iMax = this.tableColumns.length; i < iMax; i++) {
        fields[this.tableColumns[i].label] = this.tableColumns[i].field;
      }
      return fields;
    },
  },
  methods: {
    getServiceImagePath(service) {
      // eslint-disable-next-line import/no-dynamic-require,global-require
      return require(`@/assets/img/mps/mp_${service}.png`);
    },
    hasSlot(name) {
      return !!this.$slots[name];
    },
    onFilterColumns(hiddenColumns) {
      this.$set(this, 'hiddenColumns', hiddenColumns);
    },
    rowStyleClass(row) {
      if (row.vgtSelected) {
        return 'row-selected';
      }
      return '';
    },
    selectAll() {
      const table = this.$refs.dataTable;
      if (!table.allSelected) {
        table.toggleSelectAll();
      }
    },
    unselectAll() {
      this.$refs.dataTable.unselectAllInternal();
    },
    setFilters(filters) {
      this.$set(this.serverParams, 'columnFilters', filters);
      this.$nextTick(() => {
        this.loadItems();
      });
    },
    onRowClick(event) {
      const index = `selected_row_${event.pageIndex}`;
      if (event.selected) {
        this.$set(this.selectedRows, index, event.row);
      } else if (typeof this.selectedRows[index] !== 'undefined') {
        this.$delete(this.selectedRows, index);
      }
    },
    linkClass() {
      return null;
    },
    linkUrl(column, row) {
      if (typeof column.link === 'string') {
        return column.link;
      }
      const route = {
        name: column.link.route.name,
      };
      if (typeof column.link.route.params !== 'undefined') {
        route.params = {};
        const keys = Object.keys(column.link.route.params);
        for (let i = 0, iMax = keys.length; i < iMax; i++) {
          const param = keys[i];
          const field = column.link.route.params[param];
          let fieldValue;
          if (field.includes('plain:')) {
            fieldValue = field.replace('plain:', '');
          } else if (field.includes('.')) {
            const [relationName, relationColumn] = field.split('.');
            fieldValue = row[relationName][relationColumn];
          } else {
            fieldValue = row[field];
          }
          route.params[param] = fieldValue;
        }
      }
      return route;
    },
    isLink(column) {
      return typeof column.link !== 'undefined';
    },
    value(column, row) {
      if (column.value) {
        if (column.value.includes('.')) {
          const [relation, field] = column.value.split('.');
          return row[relation][field] || null;
        }
      }
      return row[column.field];
    },
    needTruncate(column, row) {
      return (
        typeof column.truncate !== 'undefined' &&
        !!column.truncate &&
        typeof row[column.field] === 'string' &&
        row[column.field] !== null &&
        row[column.field].length > 25
      );
    },
    truncatedValue(column, row) {
      const value = this.value(column, row);
      if (!this.needTruncate(column, row)) {
        return value;
      }
      let maxLength = 25;
      if (typeof column.truncate !== 'boolean') {
        maxLength = parseInt(column.truncate, 10);
      }
      if (!value || !value.length || value.length <= maxLength) {
        return value;
      }
      return `${value.substring(0, maxLength)}...`;
    },
    loadColumns(clearData = false) {
      if (clearData) {
        this.columnsFromResponse = null;
      }
      if (this.tableColumns !== null) {
        return;
      }
      this.columnsFromResponse = [];
      this.$http.get(this.columnsUrl).then((response) => {
        this.columnsFromResponse = response.data;
      });
    },
    loadItems(clearData = false) {
      if (clearData) {
        this.$set(this, 'rows', []);
        this.$set(this, 'totalRecords', null);
      }
      const queryParams = {
        ...this.baseQueryParams,
        ...this.serverParams.columnFilters,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
      };
      this.isLoading = true;
      this.$emit('begin-load', queryParams);
      this.$http
        .get(this.rowsUrl, {
          params: queryParams,
        })
        .then((response) => {
          this.isLoading = false;
          this.$set(this, 'totalRecords', response.headers['x-pagination-total-count']);
          this.$set(this.serverParams, 'perPage', response.headers['x-pagination-per-page']);
          this.$set(this.serverParams, 'page', response.headers['x-pagination-current-page']);
          this.$set(this, 'rows', response.data);
          this.$emit('load', {
            data: response.data,
            totalRecords: this.totalRecords,
          });
          if (this.$refs.dataTablePagination) {
            this.$refs.dataTablePagination.changePage(
              response.headers['x-pagination-current-page'],
              false,
            );
          }
        });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },

    onPageChange(params) {
      if (!params.noEmit) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      }
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      if (typeof params[0] !== 'undefined') {
        const sortParam = params[0];
        let { field } = sortParam;
        if (sortParam.type === 'desc') {
          field = `-${field}`;
        }
        this.updateParams({
          sort: field,
        });
        this.loadItems();
      }
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
  },
  watch: {
    $route() {
      this.$set(this, 'columnsFromResponse', null);
    },
  },
};
</script>
