<template>
  <div>
    <h2 class="title title_fz24 mt-4 mb-2">Продажи и остатки за {{ days }} {{ dateStr }}</h2>
    <sales-chart ref="salesChart" :product="product" :dates="dates"></sales-chart>
    <sales-table
      :top-stats="topStats"
      ref="salesTable"
      :product="product"
      :dates="dates"
    ></sales-table>
  </div>
</template>

<script>
import datesDiffMixin from '@/mixins/datesDiffMixin';
import SalesChart from './SalesChart.vue';
import SalesTable from './SalesTable.vue';

export default {
  props: {
    product: Object,
    topStats: Object,
    dates: {
      type: Object,
      default() {
        return {
          startDate: this.$moment().startOf('day').toDate(),
          endDate: this.$moment().endOf('day').toDate(),
        };
      },
    },
  },
  mixins: [datesDiffMixin],
  components: { SalesChart, SalesTable },
  methods: {
    update() {
      this.$refs.salesChart.getData();
      this.$refs.salesTable.update();
    },
  },
};
</script>
