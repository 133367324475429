export default {
  methods: {
    datesList(format = 'MMMM YYYY') {
      const currentMonth = this.$moment().format('MM.YYYY');
      const currentMonthName = this.$moment().format(format);
      const dates = {};
      dates[currentMonth] = currentMonthName;
      for (let i = 1; i < 11; i++) {
        const month = this.$moment().subtract(i, 'month');
        dates[month.format('MM.YYYY')] = month.format(format);
      }
      return dates;
    },
  },
};
