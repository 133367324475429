import { pluralize } from 'pluralize-ru-ts';

export default {
  computed: {
    days() {
      return Math.floor((this.dates.endDate - this.dates.startDate) / 86400000) + 1;
    },
    dateStr() {
      const pluralizeMe = pluralize('день', 'дня', 'дней');
      return pluralizeMe(this.days);
    },
  },
  methods: {
    datesList(format = 'DD.MM') {
      const { startDate } = this.dates;
      const dates = [];
      for (let i = 0; i < this.days; i++) {
        const date = this.$moment(startDate).add(i, 'days').format(format);
        dates.push(date);
      }
      return dates;
    },
  },
};
