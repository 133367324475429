export default {
  data() {
    return {
      observerRef: 'form',
    };
  },
  computed: {
    hasErrors() {
      return this.$refs[this.observerRef].invalid;
    },
  },
  methods: {
    validateFromResponse(response) {
      if (typeof response.data.validate !== 'undefined') {
        this.$refs[this.observerRef].setErrors(response.data.validate);
      }
    },
    addError(field, error) {
      this.$set(this.errors, field, error);
    },
    clearErrors() {
      this.$refs[this.observerRef].reset();
    },
  },
};
