<template>
  <div class="category-categories">
    <div v-if="currentCategory">
      <h2 class="title title_fz24">
        {{ currentCategory.vpath }}
        <span>за {{ days }} {{ dateStr }}</span>
      </h2>
      <a :href="currentCategory.vurl" class="subtitle">{{ currentCategory.vurl }}</a>
      <!--<top-stats :current-category="currentCategory" :dates="dates"></top-stats>-->
    </div>
    <data-table
      ref="itemsTable"
      selectable
      exportable
      :filters="filtersConfig"
      :columns-url="columnsUrl"
      :rows-url="rowsUrl"
      :base-query-params="baseQueryParams"
    >
      <template #top-left>
        <h2 class="title title_fz24 mt-4 mb-2">{{ pageTitle }}</h2>
        <div class="filter-container">
          <div class="d-flex align-items-center">
            <span class="me-2">Выбран период:</span>
            <div class="filter-calendar" style="pointer-events: none">
              <date-range-picker-button
                v-model="dates"
                @change="getData"
              ></date-range-picker-button>
            </div>
          </div>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/dataTable/DataTable.vue';
import Category from '../Category.vue';

export default {
  mixins: [Category],
  components: { DataTable },
  data() {
    return {
      page: 'categories',
    };
  },
  computed: {
    pageTitle() {
      return 'Категории';
    },
  },
  methods: {
    getData() {
      this.$refs.itemsTable.loadItems();
    },
  },
};
</script>
