<template>
  <div
    class="modal fade"
    id="formModal"
    tabindex="-1"
    aria-labelledby="formModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="formModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ValidationObserver :ref="formRefName" v-slot="{ errors }">
            <form @submit.prevent="onFormSubmit">
              <div
                v-for="(config, fieldName) in fields"
                :key="`${fieldName}_field`"
                class="form-floating mb-3"
              >
                <component
                  :is="fieldComponent(config)"
                  :config="config"
                  :field="fieldName"
                  :value="config.value || null"
                  v-bind="componentProps(config)"
                  @input="onFieldInput"
                />
                <label :for="config.id">
                  {{ config.labelText }}
                </label>
                <div v-if="errors[fieldName]" class="invalid-feedback">
                  {{ errors[fieldName][0] }}
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ cancelBtnText }}
          </button>
          <button type="button" @click.prevent="onFormSubmit" class="btn btn-primary">
            {{ okBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

import TextField from './components/fields/TextField.vue';
import SelectField from './components/fields/SelectField.vue';
import FileField from './components/fields/FileField.vue';

export default {
  components: {
    TextField,
    SelectField,
    FileField,
  },
  props: {
    formRefName: {
      type: String,
      default: 'modalForm',
    },
    isOpened: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    fields: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    okBtnText: {
      type: String,
      required: false,
      default: 'Сохранить',
    },
    cancelBtnText: {
      type: String,
      required: false,
      default: 'Отмена',
    },
  },
  data() {
    return {
      modal: null,
      modalElement: null,
      formFields: {},
    };
  },
  methods: {
    addErrors(errors = {}) {
      this.$refs[this.formRefName].setErrors(errors);
    },
    componentProps(config) {
      return config.componentProps || {};
    },
    onFieldInput(field, value) {
      this.$emit('updateFormField', field, value);
    },
    fieldComponent(field) {
      return field.component || 'text-field';
    },
    inputPlaceholder(field) {
      return field.inputPlaceholder ? field.inputPlaceholder : field.labelText;
    },
    async onFormSubmit() {
      const isValid = await this.$refs[this.formRefName].validate();
      if (!isValid) {
        return;
      }
      this.$emit('submit');
    },
    openModal() {
      this.modal.show();
    },
    closeModal() {
      this.modal.hide();
    },
    onModalHidden() {
      this.$emit('close');
      this.clearForm();
    },
    clearForm() {
      const form = this.modalElement.querySelector('form');
      if (form.length) {
        form.reset();
      }
      this.$refs[this.formRefName].reset();
    },
  },
  watch: {
    isOpened(v) {
      if (v) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  mounted() {
    this.modalElement = document.getElementById('formModal');
    this.modal = new Modal(this.modalElement);
    this.modalElement.addEventListener('hidden.bs.modal', this.onModalHidden);
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal', this.onModalHidden);
    this.modal.dispose();
  },
};
</script>
