<template>
  <div class="main-grid">
    <sidebar></sidebar>
    <div class="main d-flex align-items-stars flex-column">
      <layout-header></layout-header>
      <main>
        <router-view></router-view>
      </main>
      <footer class="mt-auto">
        <div>2023 © {{ companyName }}</div>
      </footer>
    </div>
  </div>
</template>

<script>
import LayoutHeader from '@/components/layout/Header.vue';
import Sidebar from '@/components/layout/Sidebar.vue';

export default {
  components: { LayoutHeader, Sidebar },
  computed: {
    companyName() {
      if (!this.$auth.check()) {
        return '';
      }
      return this.$auth.user().franchise.name;
    },
  },
};
</script>
