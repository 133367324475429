<template>
  <div :class="containerClass">
    <input
      type="number"
      class="form-control"
      placeholder="Введите текст поиска"
      v-bind="inputProps"
      @input="onInput"
      :value="filter.value"
    />
    <div class="d-flex mt-2">
      <button class="btn btn-primary ms-auto" @click.prevent="onSetValue">Применить</button>
    </div>
  </div>
</template>

<script>
import FilterText from './FilterText.vue';

export default {
  mixins: [FilterText],
};
</script>
