<template>
  <div :class="containerClass">{{ type }}</div>
</template>

<script>
import filter from './filter';

export default {
  mixins: [filter],
};
</script>
