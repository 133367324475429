<template>
  <div :class="containerClasses">
    <a href="#" class="category-link fw-bold" @click.prevent="childrenOpened = !childrenOpened">
      {{ category.vname }}
      <i class="rigel-icon-sort-down ms-1"></i>
    </a>
    <ul class="w-100 child-links-list" v-if="childrenOpened">
      <li class="current-category-link">
        <router-link
          class="category-link"
          :to="{
            name: 'analytics:category',
            params: { categoryId: category.vsourceid, page: 'goods' },
          }"
          exact
          >Открыть раздел {{ category.vname }}</router-link
        >
      </li>
      <template v-if="hasChildren">
        <li v-for="child in category.children" :key="`subcategory-child-${child.vsourceid}`">
          <component
            :is="$options.name"
            v-if="child.children.length"
            container-class="pt-0"
            :category="child"
          ></component>
          <router-link
            v-else
            class="category-link"
            :to="{
              name: 'analytics:category',
              params: { categoryId: child.vsourceid, page: 'goods' },
            }"
            exact
            >{{ child.vname }}</router-link
          >
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'subcategory',
  props: ['category', 'containerClass'],
  data() {
    return {
      childrenOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      openedFromStore: 'allCategoriesOpened',
    }),
    containerClasses() {
      const classes = ['subcategory-container'];
      if (this.containerClass) {
        classes.push(this.containerClass);
      }
      return classes;
    },
    hasChildren() {
      return !!this.category.children.length;
    },
  },
  watch: {
    openedFromStore: {
      deep: true,
      immediate: true,
      handler(v) {
        this.childrenOpened = v;
      },
    },
  },
};
</script>
