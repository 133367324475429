<template>
  <div :class="containerClass">
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">От</span>
      <input
        type="number"
        class="form-control"
        @input="onSetRangeValue($event, 0)"
        :value="filter.value[0]"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">До</span>
      <input
        type="number"
        class="form-control"
        @input="onSetRangeValue($event, 1)"
        :value="filter.value[1]"
      />
    </div>
    <div class="d-flex mt-2">
      <button class="btn btn-primary ms-auto" @click.prevent="onSetValue">Применить</button>
    </div>
  </div>
</template>

<script>
import filter from './filter';

export default {
  mixins: [filter],
  data() {
    return {
      value: [null, null],
    };
  },
  methods: {
    onSetRangeValue($event, index) {
      this.$set(this.value, index, $event.target.value);
    },
  },
};
</script>
