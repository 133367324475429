<template>
  <div class="sales__chart d-none d-lg-block">
    <canvas id="salesChart" height="100"></canvas>
  </div>
</template>

<script>
import ChartMixin from '@/mixins/ChartMixin';

const chartColors = {
  red: 'rgb(255, 99, 132)',
  blue: 'rgb(54, 162, 235)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  magenta: 'rgb(255, 0, 255)',
  black: 'rgb(0, 0, 0)',

  reda: 'rgba(255, 99, 132, 0.3)',
  bluea: 'rgba(54, 162, 235, 0.3)',
  orangea: 'rgba(255, 159, 64, 0.3)',
  yellowa: 'rgba(255, 205, 86, 0.3)',
  greena: 'rgba(75, 192, 192, 0.3)',
  purplea: 'rgba(153, 102, 255, 0.3)',
  greya: 'rgba(201, 203, 207,0.3)',
  magentaa: 'rgb(255, 0, 255, 0.3)',
  blacka: 'rgb(0, 0, 0, 0.3)',
};

export default {
  props: ['dates', 'product'],
  mixins: [ChartMixin],
  data() {
    return {
      labels: [],
      orders: [],
      quantity: [],
      comments: [],
      rating: [],
      price: [],
      revenue: [],
    };
  },
  computed: {
    chartCanvas() {
      return document.querySelector('#salesChart').getContext('2d');
    },
    gradient() {
      return this.createGradient(
        this.chartCanvas,
        'rgba(148, 148, 148, 0.80)',
        'rgba(214, 214, 214, 0.00)',
        500,
      );
    },
    gradient2() {
      return this.createGradient(
        this.chartCanvas,
        'rgba(148, 148, 148, 0.80)',
        'rgba(214, 214, 214, 0.00)',
        500,
      );
    },
    chartConfig() {
      return {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: 'Продажи',
              fill: true,
              yAxisID: 'y-axis-sales',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.orders,
              borderColor: chartColors.red,
              backgroundColor: chartColors.reda,
            },
            {
              label: 'Остатки',
              fill: true,
              yAxisID: 'y-axis-remains',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.quantity,
              borderColor: chartColors.blue,
              backgroundColor: chartColors.bluea,
            },
            {
              label: 'Цена',
              fill: true,
              yAxisID: 'y-axis-price',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.price,
              borderColor: chartColors.orange,
              backgroundColor: chartColors.orangea,
            },
            {
              label: 'Выручка',
              fill: true,
              yAxisID: 'y-axis-revenue',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.revenue,
              borderColor: chartColors.yellow,
              backgroundColor: chartColors.yellowa,
            },
            {
              label: 'Комментарии',
              fill: true,
              yAxisID: 'y-axis-comments',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.comments,
              borderColor: chartColors.green,
              backgroundColor: chartColors.greena,
            },
            {
              label: 'Рейтинг',
              fill: true,
              yAxisID: 'y-axis-rating',
              showLine: true,
              spanGaps: true,
              pointHoverRadius: 5,
              cubicInterpolationMode: 'monotone',
              data: this.rating,
              borderColor: chartColors.purple,
              backgroundColor: chartColors.purplea,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: true,
            },
          },
          layout: {
            padding: 0,
          },
          scales: {
            y: {
              type: 'linear',
              display: 'auto',
              position: 'left',
              beginAtZero: true,
            },
            'y-axis-sales': {
              type: 'linear',
              display: 'auto',
              position: 'right',
              beginAtZero: true,
              gridLines: { drawOnChartArea: true },
              ticks: {
                color: chartColors.red,
              },
            },
            'y-axis-revenue': {
              type: 'linear',
              display: 'auto',
              position: 'right',
              beginAtZero: true,
              gridLines: { drawOnChartArea: false },
              ticks: {
                color: chartColors.yellow,
              },
            },
            'y-axis-remains': {
              type: 'linear',
              display: 'auto',
              position: 'left',
              beginAtZero: true,
              gridLines: { drawOnChartArea: false },
              ticks: {
                color: chartColors.blue,
              },
            },
            'y-axis-price': {
              type: 'linear',
              display: 'auto',
              position: 'left',
              beginAtZero: true,
              gridLines: { drawOnChartArea: false },
              ticks: {
                color: chartColors.orange,
              },
            },
            'y-axis-comments': {
              type: 'linear',
              display: 'auto',
              position: 'left',
              beginAtZero: true,
              gridLines: { drawOnChartArea: false },
              ticks: {
                color: chartColors.green,
              },
            },
            'y-axis-rating': {
              type: 'linear',
              display: 'auto',
              position: 'left',
              beginAtZero: true,
              gridLines: { drawOnChartArea: false },
              ticks: {
                color: chartColors.purple,
              },
            },
          },
        },
      };
    },
  },
  methods: {
    getData() {
      this.$http
        .get('/analytics/goods/sales-chart', {
          params: {
            id: this.product.sku,
            startDate: this.dates.startDate ?
              this.$moment(this.dates.startDate).format('DD.MM.YYYY') :
              null,
            endDate: this.dates.endDate ?
              this.$moment(this.dates.endDate).format('DD.MM.YYYY') :
              null,
          },
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.dates &&
            response.data.orders &&
            response.data.quantity
          ) {
            this.$set(this, 'labels', response.data.dates);
            this.$set(this, 'orders', response.data.orders);
            this.$set(this, 'quantity', response.data.quantity);
            this.$set(this, 'revenue', response.data.revenue);
            this.$set(this, 'price', response.data.price);
            this.$set(this, 'rating', response.data.rating);
            this.$set(this, 'comments', response.data.comments);
            this.$nextTick(() => {
              this.runChart();
            });
          }
        });
    },
    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (tooltipEl) {
        tooltipEl.remove();
      }

      tooltipEl = document.createElement('div');
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = '1';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      tooltipEl.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="22" viewBox="0 0 49 22" fill="none">
            <path d="M0.765625 2.95801C0.765625 1.85344 1.66106 0.958008 2.76562 0.958008H46.5714C47.676 0.958008 48.5714 1.85344 48.5714 2.95801V13.1493C48.5714 14.2539 47.676 15.1493 46.5714 15.1493H29.2317C28.2538 15.1493 27.4193 15.8566 27.2588 16.8213L26.7577 19.8349C26.3835 22.0846 23.1397 22.0553 22.8062 19.7993L22.3713 16.8569C22.2263 15.8759 21.3844 15.1493 20.3928 15.1493H2.76562C1.66105 15.1493 0.765625 14.2539 0.765625 13.1493V2.95801Z" fill="#00892F"/>
        </svg>
    `;

      chart.canvas.parentNode.appendChild(tooltipEl);

      return tooltipEl;
    },
    externalTooltipHandler(context) {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const bodyLines = tooltip.body.map((b) => b.lines);
        const tooltipDiv = document.createElement('div');
        bodyLines.forEach((body) => {
          const text = document.createTextNode(`${body}P`);
          tooltipDiv.appendChild(text);
        });
        tooltipDiv.style.color = '#FFF';
        tooltipDiv.style.textAlign = 'center';
        tooltipDiv.style.fontSize = '10px';
        tooltipDiv.style.fontWeight = '700';
        tooltipDiv.style.position = 'absolute';
        tooltipDiv.style.left = '0';
        tooltipDiv.style.right = '0';
        tooltipDiv.style.top = '3px';

        tooltipEl.appendChild(tooltipDiv);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font

      tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
      tooltipEl.style.top = `${positionY + tooltip.caretY - 32}px`;
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.opacity = '1';
    },
    createGradient(canvas, color1, color2, num) {
      const gradient = canvas.createLinearGradient(0, 0, 0, num);
      gradient.addColorStop(0, color1);
      gradient.addColorStop(1, color2);

      return gradient;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
