<template>
  <div class="filter-th">
    <div>
      <input
        :value="value"
        @input="onInput"
        type="text"
        class="vgt-input"
        :placeholder="placeholder"
        v-bind="$props"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    timeout: {
      type: Number,
      default: 500,
    },
    placeholder: String,
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  methods: {
    clearTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    onInput($event) {
      this.clearTimeout();
      this.$emit('input', $event.target.value);
      this.timeoutId = setTimeout(() => {
        this.$emit('done', $event.target.value);
      }, this.timeout);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  .input-group-text {
    border-right: none;
    background: #fff;
  }
  .form-control {
    border-left: none;
    &:focus {
      border-color: var(--bs-border-color);
      box-shadow: none;
    }
  }
}
</style>
