<script>
import StocksRemains from './StocksRemains.vue';

export default {
  mixins: [StocksRemains],
  data() {
    return {
      canvasId: 'stocksSales',
      pageName: 'Продажи',
      tableHeadLabel: 'Продажи',
      needShowHistoryButton: true,
    };
  },
  computed: {
    dataUrl() {
      return `/analytics/goods/stocks-sales?id=${this.product.sku}`;
    },
  },
};
</script>
