<template>
  <section class="product-stats">
    <div class="product-stats__wrapper">
      <div class="product-stats__item">
        <div class="product-stats__name">Суммарно за {{ days }} {{ dateStr }}</div>
        <div class="product-stats__num">{{ totalSales }} шт</div>
        <div class="product-stats__percent">
          <span>{{ totalSum }}</span>
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/1.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/1.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item">
        <div class="product-stats__name">
          Потенциал
          <i
            class="fa-regular fa-circle-question"
            title="Потенциал выручки (Выручка / Число дней в наличии) * Выбранный период"
          ></i>
          / Упущенная выручка
          <i class="fa-regular fa-circle-question" title="Потенциал - Выручка"></i>
        </div>
        <div class="product-stats__num">{{ potential }}</div>
        <div class="product-stats__percent">
          <span>{{ lostProfit }}</span>
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/2.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/2.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item">
        <div class="product-stats__name">Среднее (в день)</div>
        <div class="product-stats__num">{{ averagePerDay }} шт</div>
        <div class="product-stats__percent">
          <span>{{ averageSum }}</span>
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/3.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/3.png" alt=""
          /></picture>
        </div>
      </div>
      <div class="product-stats__item">
        <div class="product-stats__name">Среднее (при наличии)</div>
        <div class="product-stats__num">{{ averagePerDayWithRemains }} шт</div>
        <div class="product-stats__percent">
          <span>{{ averageSumWithRemains }}</span>
        </div>
        <div class="product-stats__img">
          <picture>
            <source srcset="@/assets/img/product-stats/4.webp" type="image/webp" />
            <img src="@/assets/img/product-stats/4.png" alt="" />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import datesDiffMixin from '@/mixins/datesDiffMixin';

export default {
  props: ['dates', 'productId'],
  mixins: [datesDiffMixin],
  data() {
    return {
      totalSales: 0,
      totalSum: 0,
      potential: 0,
      lostProfit: 0,
      averagePerDay: 0,
      averagePerDayWithRemains: 0,
      averageSum: 0,
      averageSumWithRemains: 0,
    };
  },
  methods: {
    doUpdate() {
      this.$http
        .get('/analytics/goods/sales-stats', {
          params: {
            id: this.productId,
            startDate: this.dates.startDate ?
              this.$moment(this.dates.startDate).format('DD.MM.YYYY') :
              null,
            endDate: this.dates.endDate ?
              this.$moment(this.dates.endDate).format('DD.MM.YYYY') :
              null,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'totalSales', response.data.totalSales || 0);
            this.$set(this, 'totalSum', response.data.totalSum || 0);
            this.$set(this, 'potential', response.data.potential || 0);
            this.$set(this, 'lostProfit', response.data.lostProfit || 0);
            this.$set(this, 'averagePerDay', response.data.averagePerDay || 0);
            this.$set(
              this,
              'averagePerDayWithRemains',
              response.data.averagePerDayWithRemains || 0,
            );
            this.$set(this, 'averageSum', response.data.averageSum || 0);
            this.$set(this, 'averageSumWithRemains', response.data.averageSumWithRemains || 0);
            this.$emit('loaded', response.data);
          }
        });
    },
    update() {
      this.$nextTick(() => {
        this.doUpdate();
      });
    },
  },
  mounted() {
    this.doUpdate();
  },
};
</script>
