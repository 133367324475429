import moment from 'moment';
import ru from 'moment/locale/ru';

moment.updateLocale('ru', ru);

export default {
  install(Vue) {
    Vue.prototype.$moment = moment;
  },
};
