<template>
  <li class="header__nav-item dropdown-center">
    <a
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-expanded="false"
      class="header__link active"
    >
      <i class="fa-solid fa-chart-pie"></i>
      <span>Категории</span>
    </a>
    <ul class="dropdown-menu" style="">
      <li v-for="(link, index) in links" :key="`category-${index}-link`">
        <router-link :to="link.url" class="dropdown-item" active-class="active">{{
          link.label
        }}</router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  computed: {
    category() {
      return this.$store.getters.currentCategory;
    },
    links() {
      if (!this.category) {
        return [];
      }
      return [
        {
          label: 'Товары',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'goods',
            },
          },
        },
        {
          label: 'Категории',
          url: {
            name: 'analytics:category-categories',
            params: {
              categoryId: this.category.vsourceid,
            },
          },
        },
        {
          label: 'Бренды',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'brands',
            },
          },
        },
        {
          label: 'Анализ ниш',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'category-analysis',
            },
          },
        },
        {
          label: 'Ценовой сегмент',
          url: {
            name: 'analytics:category',
            params: {
              categoryId: this.category.vsourceid,
              page: 'price-segment',
            },
          },
        },
      ];
    },
  },
};
</script>
