<template>
  <div class="footer__navigation__page-info">
    <div v-if="infoFn" v-html="infoFn(infoParams)"></div>
    <form v-else-if="mode === 'pages'" @submit.prevent>
      <label :for="id" class="page-info__label">
        <span>{{ pageText }}</span>
        <input
          :id="id"
          aria-describedby="change-page-hint"
          aria-controls="vgb-table"
          class="footer__navigation__page-info__current-entry"
          type="text"
          @keyup.enter.stop="changePage"
          :value="currentPage"
        />
        <span>{{ pageInfo }}</span>
      </label>
      <span id="change-page-hint" style="display: none">
        Type a page number and press Enter to change the page.
      </span>
    </form>
    <div v-else-if="mode === 'pagination'">
      <button
        class="footer__navigation__page-btn"
        :class="{ active: buttonIsActive(pageNumber) }"
        v-for="(pageNumber, index) in visiblePageNumbers"
        :key="`page_${pageNumber}_${index}`"
        @click="goToPage(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </div>
    <div v-else>
      {{ recordInfo }}
    </div>
  </div>
</template>

<script>
import { PAGINATION_MODES } from 'vue-good-table/src/components/utils/constants';

export default {
  name: 'VgtPaginationPageInfo',
  props: {
    currentPage: {
      default: 1,
    },
    lastPage: {
      default: 1,
    },
    totalRecords: {
      default: 0,
    },
    ofText: {
      default: 'of',
      type: String,
    },
    pageText: {
      default: 'page',
      type: String,
    },
    currentPerPage: {},
    mode: {
      default: PAGINATION_MODES.Records,
    },
    infoFn: { default: null },
  },
  data() {
    return {
      id: this.getId(),
    };
  },
  computed: {
    visiblePageNumbers() {
      const maxVisiblePages = 5;
      const visiblePages = [];
      const lastPage = parseInt(this.lastPage, 10);
      const currentPage = parseInt(this.currentPage, 10);

      if (lastPage <= maxVisiblePages) {
        for (let i = 1; i <= lastPage; i++) {
          visiblePages.push(i);
        }
      } else if (currentPage <= maxVisiblePages - 2) {
        for (let i = 1; i <= maxVisiblePages - 1; i++) {
          visiblePages.push(i);
        }
        visiblePages.push('...');
        visiblePages.push(lastPage);
      } else if (currentPage >= lastPage - maxVisiblePages + 3) {
        visiblePages.push(1);
        visiblePages.push('...');
        for (let i = lastPage - maxVisiblePages + 2; i <= lastPage; i++) {
          visiblePages.push(i);
        }
      } else {
        visiblePages.push(1);
        visiblePages.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(i);
        }
        visiblePages.push('...');
        visiblePages.push(lastPage);
      }

      return visiblePages;
    },
    pageInfo() {
      return `${this.ofText} ${this.lastPage}`;
    },
    firstRecordOnPage() {
      return (this.currentPage - 1) * this.currentPerPage + 1;
    },
    lastRecordOnPage() {
      // if the setting is set to 'all'
      if (this.currentPerPage === -1) {
        return this.totalRecords;
      }
      return Math.min(this.totalRecords, this.currentPage * this.currentPerPage);
    },
    recordInfo() {
      return `${this.computedFirst} - ${this.lastRecordOnPage} ${this.ofText} ${this.totalRecords}`;
    },
    recordInfoMobile() {
      return `${this.computedFirst} - ${this.lastRecordOnPage} ${this.ofText} ${this.totalRecords}`;
    },
    computedFirst() {
      if (this.lastRecordOnPage === 0) {
        return 0;
      }
      return this.firstRecordOnPage;
    },
    infoParams() {
      let first = this.firstRecordOnPage;
      const last = this.lastRecordOnPage;
      if (last === 0) {
        first = 0;
      }
      return {
        firstRecordOnPage: first,
        lastRecordOnPage: last,
        totalRecords: this.totalRecords,
        currentPage: this.currentPage,
        totalPage: this.lastPage,
      };
    },
  },
  methods: {
    buttonIsActive(buttonPage) {
      return parseInt(buttonPage, 10) === parseInt(this.currentPage, 10);
    },
    goToPage(number) {
      this.$emit('page-changed', number);
    },
    getId() {
      return `vgt-page-input-${Math.floor(Math.random() * Date.now())}`;
    },
    changePage(event) {
      const value = parseInt(event.target.value, 10);

      //! invalid number
      if (Number.isNaN(value) || value > this.lastPage || value < 1) {
        event.target.value = this.currentPage;
        return;
      }

      //* valid number
      event.target.value = value;
      this.$emit('page-changed', value);
    },
  },
  mounted() {},
  components: {},
};
</script>

<style lang="scss" scoped></style>
